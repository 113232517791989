import React from "react";
import { styled } from "@mui/material/styles";
import {
    useTheme,
    Paper,
    Grid,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    Avatar,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Snackbar,
    Alert,
    TextField,
    Fab,
    TextareaAutosize,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Form, Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { tokens } from "../../theme";
import axiosClient from "../../axios";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LanguageIcon from "@mui/icons-material/Language";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import BusinessIcon from "@mui/icons-material/Business";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LinkIcon from "@mui/icons-material/Link";
import SendIcon from "@mui/icons-material/Send";
import prettyDate from "../../formatdate";
import ViewPDF from "../requirements/ViewPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import UserContext from "../../contexts/UserContext";
import { forEach, over, set } from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import ReplayIcon from "@mui/icons-material/Replay";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { debounce } from "lodash";
import { ArchiveOutlined } from "@mui/icons-material";
import EscalatorIcon from "@mui/icons-material/Escalator";
import { id } from "date-fns/locale";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

export default function InspectionDetails({ inspectionID, currentIndexTab }) {
    let count = 0;
    let appendText = "";
    // const base_url = "http://localhost:8000/";
    const base_url = "https://portal.occusurv.co.za/api/public/";
    const theme = useTheme();
    const [inspection, setInspection] = useState(null);
    const colors = tokens(theme.palette.mode);
    const [escalation, setEscalation] = useState(null);
    const [inspectionAuditID, setInspectionAuditID] = useState(null);
    const [requirements, setRequirements] = useState(null);
    const [recommendations, setRecommendations] = useState([]);
    const [underwriterNotes, setUnderwriterNotes] = useState("");
    const [requirement_queries, setRequirementQueries] = useState(null);
    const { user } = useContext(UserContext);
    const [insurer, setInsurer] = useState({});
    const [ipAddress, setIPAddress] = useState("");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState(
        "Email reminder sent successfully!"
    );
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openAddEscalateDialog, setOpenAddEscalateDialog] = useState(false); // Esc
    const [openCompletedDialog, setOpenCompletedDialog] = useState(false);
    const [completedRequriment, setCompletedRequirement] = useState({});
    const [deleteFileRequirement, setDeleteFileRequirement] = useState({});
    const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState(false);
    const [openEmailSurveyor, setOpenEmailSurveyor] = useState(false);
    const [emailMessage, setEmailMessage] = useState("");
    const [openEditDatesDialog, setOpenEditDatesDialog] = useState(false);
    const [editDates, setEditDates] = useState({});
    const [inspectionEdit, setInspectionEdit] = useState({});
    const [escalateSurvey, setEscalateSurvey] = useState({}); //Esc
    const [scroll, setScroll] = useState("paper");
    const [queries, setQueries] = useState(null);
    const [requirementID, setRequirementID] = useState(null);
    const [regExpectedDate, setRegExpectedDate] = useState(null);
    const [auditID, setAuditID] = useState(null);
    const [currentReq, setCurrentReq] = useState(null);
    const [queryText, setQueryText] = useState("");
    const [resetRequirement, setResetRequirement] = useState({
        reason: "",
        requirement: "",
    });
    const [deleteFile, setDeleteFile] = useState({
        reason: "",
        requirement: "",
    });
    const [queryDate, setQueryDate] = useState("");
    const [brokerEmail, setBrokerEmail] = useState("");
    const [queriedRequirements, setQueriedRequirements] = useState(null);
    const [logoURL, setLogoURL] = useState("");
    const [hexColor, setHexColor] = useState("#2a2d64");
    const [replyFailed, setReplyFailed] = useState(false);
    const [replySuccess, setReplySuccess] = useState(false);
    const [waiverChecked, setWaiverChecked] = useState(false);
    const [openResurveyDialog, setOpenResurveyDialog] = useState(false);
    const [overdueReq, setOverdueReq] = useState(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

    const resurvey_email = "info@occusurv.co.za";
    const portal_email = "portal@occusurv.co.za";

    let navigate = useNavigate();

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    // Get Insurer details
    useEffect(() => {
        async function getInsurer() {
            const response = await axiosClient.get(
                `/insurer/${user?.insurer_id}`
            );
            if (response.data) {
                // console.log("INSURER: ", response.data);
                setInsurer(response.data);
            }
        }
        getInsurer();
    }, []);

    // Edit Inspection Dialog handlers
    const handleClickOpenEdit = () => {
        setInspectionEdit({
            id: inspection.id,
            audit_policy_number: inspection.audit_policy_number,
            audit_policy_holder: inspection.audit_policy_holder,
            audit_contact_name: inspection.audit_contact_name,
            audit_contact_number: inspection.audit_contact_number,
            audit_broker_name: inspection.audit_broker_name,
            audit_broker_contact_number: inspection.audit_broker_contact_number,
            reminder_days: inspection.reminder_days,
            audit_broker_email: inspection.audit_broker_email,
            audit_insurer_email: inspection.audit_insurer_email,
        });
        setOpenEditDialog(true);
    };

    const handleClickAddEscalation = () => {
        // Reset the escalateSurvey
        setEscalateSurvey({
            id: inspection.id,
            audit_id: inspection.audit_id,
            escalation_type: "",
            escalation_details: "",
            escalated_by: user?.id,
        }) 
        setOpenAddEscalateDialog(true);
    };

    //Escalate
    const handleAddEscalateClose = () => {
        setOpenAddEscalateDialog(false);
    };

    const handleEditClose = () => {
        setOpenEditDialog(false);
    };

    // Email surveyor Dialog handlers
    const handleEmailSurveyor = () => {
        setOpenEmailSurveyor(true);
    };

    const handleEmailSurveyorClose = () => {
        setOpenEmailSurveyor(false);
    };

    const handleEmailSurveyorSubmit = (event) => {
        // console.log("Email_MSG: ", emailMessage)
        // Use axios to send email
        let body =
            "<strong>This email is sent from Occusurv Portal on behalve " +
            inspection.audit_insurer_name +
            " for the following survey inquiry: </strong><br />";
        body +=
            "Policy Number: " +
            inspection.audit_policy_number +
            "<br />Policyholder: " +
            inspection.audit_policy_holder +
            "<br />";
        body +=
            "Survey Date: " +
            prettyDate(inspection.audit_survey_date) +
            "<br />" +
            "Broker: " +
            inspection.audit_broker_name +
            "<br />";
        body += "Risk Address: " + inspection.audit_physical_address + "<br />";
        body +=
            "Survey Link: <a href='" +
            inspection.audit_web_report_link +
            "' target='_blank'>Click here</a><br /><br />";
        body +=
            "<strong>Message:</strong><br />" + emailMessage + "<br /><br />";
        body +=
            "<strong> The email was sent by " +
            user?.name +
            " (" +
            user?.email +
            ") CCed. Please Reply All or to this sender's email address</strong>.<br /><br />";
        body +=
            "<h6>Sent from Occusurv Portal (https://portal.occusurv.co.za)</h6>";
        axiosClient
            .post(
                "/send_email",
                // Create a json object with the data to send
                {
                    from_email: user.email,
                    from_name: user.name,
                    recipient_email: "info@occusurv.co.za",
                    cc_email: user.email,
                    subject: "Occusurv Portal - Inquiry on Survey",
                    body: body,
                    filename: null,
                }
            )
            .then((response) => {
                setOpenEmailSurveyor(false);
                setSnackBarMessage(
                    "Email sent successfully to the surveyor, a copy sent to your email!"
                );
                setSnackBarOpen(true);
                // console.log("Response: ", response);
                // Record log
                axiosClient
                    .post("/audit_trails", {
                        user_id: user.id.toString(),
                        insurer_id: user.insurer_id,
                        inspection_id: inspection.id,
                        ip_address: ipAddress,
                        title: "Contact Surveyor",
                        description: `${user.name}(${user.email}) have sent an email to surveyor about the following survey: 
                        Policy Number: ${inspection.policy_number}, Policyholder: ${inspection.policy_holder}. Message: ${emailMessage}`,
                    })
                    .then((response) => {})
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    // Open queries dialog
    const handleClickOpen =
        (requirement_id, audit_id, requirement_details, expected_date) =>
        () => {
            setRequirementID(requirement_id);
            setAuditID(audit_id);
            setCurrentReq(requirement_details);
            setRegExpectedDate(expected_date);
            const queries = [];
            forEach(requirement_queries, (requirement_query) => {
                if (requirement_query.requirement_id == requirement_id) {
                    queries.push({
                        id: requirement_query.id,
                        requirement_id: requirement_query.requirement_id,
                        audit_id: requirement_query.audit_id,
                        from_email: requirement_query.from_email,
                        to_email: requirement_query.to_email,
                        query_details: requirement_query.query_details,
                        query_date: requirement_query.created_at,
                    });
                    // Search for Broker email
                    if (requirement_query.from_email != user.email) {
                        setBrokerEmail(requirement_query.from_email);
                    }
                }
            });
            // console.log("New QUERIES: ", queries);
            setQueries(queries);
            setOpenDialog(true);
            setScroll("paper");
        };

    const handleClose = () => {
        setOpenDialog(false);
    };

    // Get IP address of this client
    useEffect(() => {
        fetch("https://api.ipify.org?format=json")
            .then((response) => response.json())
            .then((data) => setIPAddress(data.ip))
            .catch((error) => console.log(error));
    }, []);

    // Get Logo of the Insurer URL
    useEffect(() => {
        async function getLogoUrl() {
            const response = await axiosClient.get(
                `/insurances/logo_url/${user.insurer_id}`
            );
            if (response.data) {
                setLogoURL(response.data[0]);
            }
        }
        getLogoUrl();
    }, []);

    // Get Hex colour code of the Insurer
    useEffect(() => {
        async function getHexColour() {
            const response = await axiosClient.get(
                `/insurances/hex_colour/${user.insurer_id}`
            );
            if (response.data) {
                setHexColor(response.data[0]);
            }
        }
        getHexColour();
    }, []);

    const fetchDataInspectionID = debounce(async () => {
        // Get Inspection details
        const inspectionResponse = await axiosClient.get(
            `/inspections/${inspectionID}`
        );
        if (inspectionResponse.data) {
            // console.log("INSPECTION: ", inspectionResponse.data);
            setInspection(inspectionResponse.data);
            setInspectionAuditID(inspectionResponse.data.audit_id);
        }

        // If inspectionAuditID is null, return
        if (!inspectionResponse.data.audit_id) {
            return;
        }

        // Get Requirements of an Inspection
        const requirementsResponse = await axiosClient.get(
            `/inspections/requirements/${inspectionResponse.data.audit_id}`
        );
        if (requirementsResponse.data) {
            // console.log("REQUIREMENTS: ", requirementsResponse.data);
            setRequirements(requirementsResponse.data);
            // Check if there are any Overdue requirements
            const today = new Date();
            today.setHours(0, 0, 0, 0); // set the time to 00:00:00
            const overdueR = requirementsResponse.data.some((item) => {
                if (
                    item.checked == 1 &&
                    item.requirement_status != "Completed" &&
                    item.requirement_status != "Waived"
                ) {
                    const expectedDate = new Date(item.expected_date);
                    return expectedDate < today;
                }
            });
            setOverdueReq(overdueR);
        }

        // Get Recommendations of an Inspection
        const recommendationsResponse = await axiosClient.get(
            `/inspections/recommendations/${inspectionResponse.data.audit_id}`
        );
        if (recommendationsResponse.data) {
            setRecommendations(
                // recommendationsResponse.data[0]?.recommendation_details
                recommendationsResponse.data
            );
        }

        // Get Note written by underwriter of an Inspection
        const notesResponse = await axiosClient.get(
            `/inspections/notes/${inspectionResponse.data.audit_id}`
        );
        if (notesResponse.data.length > 0) {
            // Loop through notesResponse.data[0]?.underwriter_notes and replace <br /> with \n
            let notes = notesResponse.data[0]?.underwriter_notes;
            notes = notes.replace(/<br\s*\/?>/gi, "<br />");
            setUnderwriterNotes(notes);
        }
    }, 300); // delay in ms
    useEffect(() => {
        fetchDataInspectionID();
    }, [inspectionID]);

    const fetchDataInspectionReq = debounce(async () => {
        if (
            !inspection ||
            !inspection.audit_broker_email ||
            !inspection.audit_insurer_email
        ) {
            return;
        }

        const [reqQueriesResponse, queriedRequirementsResponse] =
            await Promise.all([
                axiosClient.get(
                    `/requirement_query/from_and_to_queries/${inspection.audit_broker_email}/${inspection.audit_insurer_email}`
                ),
                axiosClient.get(
                    `/requirement_query/queried_requirements/${inspection.audit_id}`
                ),
            ]);

        if (reqQueriesResponse.data) {
            setRequirementQueries(reqQueriesResponse.data);
        }

        if (queriedRequirementsResponse.data) {
            const unique_req_ids = new Set();
            forEach(queriedRequirementsResponse.data, (requirement) => {
                unique_req_ids.add(requirement.requirement_id);
            });

            const queried_requirements = [];
            forEach(requirements, (requirement) => {
                if (unique_req_ids.has(requirement.id)) {
                    let match_queries = [];
                    forEach(queriedRequirementsResponse.data, (query) => {
                        if (query.requirement_id == requirement.id) {
                            match_queries.push(query);
                        }
                    });
                    queried_requirements.push({
                        requirement_id: requirement.id,
                        requirement_details: requirement.requirement_details,
                        requirement_queries: match_queries,
                    });
                }
            });
            setQueriedRequirements(queried_requirements);
        }
    }, 300); // delay in ms
    useEffect(() => {
        fetchDataInspectionReq();
    }, [inspection, requirements]);
    // console.log("REQUIREMENTS: ", requirements);
    // console.log("QUERIED: ", queriedRequirements);
    // console.log("queries: ", queries);

    const listItemStyles = {
        paddingTop: 0,
        paddingBottom: 0,
        // For each ListItem, apply the following styles
        "& .MuiTypography-body1": {
            // For primary text in ListItemText
            fontSize: "14px",
            fontWeight: "bold",
        },
        "& .MuiListItemText-root": {
            marginLeft: "10px",
        },
    };

    const deleteSurvey = () => {
        // console.log("Prop Inspection ID: ", inspectionID);
        // Delete the survey using axios
        if (inspectionID) {
            async function deleteInspection() {
                const response = await axiosClient
                    .delete(`/inspections/${inspectionID}`)
                    .then((response) => {
                        // console.log("Response: ", response);
                        if (response.status === 204) {
                            // Record log
                            axiosClient
                                .post("/audit_trails", {
                                    user_id: user.id.toString(),
                                    insurer_id: user.insurer_id,
                                    inspection_id: inspection.id,
                                    ip_address: ipAddress,
                                    title: "Delete Survey",
                                    description: `${user.name}(${user.email}) have deleted the survey: 
                                    Policy Number: ${inspection.audit_policy_number}, Policyholder: ${inspection.audit_policy_holder}.`,
                                })
                                .then((response) => {
                                    setSnackBarMessage(
                                        "Survey deleted successfully!"
                                    );
                                    setSnackBarOpen(true);
                                })
                                .catch((error) => {
                                    console.log("ERROR: ", error);
                                });
                            // Redirect to load surveys
                            navigate("/load_surveys");
                        }
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            }
            deleteInspection();
        }
    };

    const resetSurvey = () => {
        // console.log("Prop Inspection: ", inspection);
        // console.log("Prop Requirements", requirements);
        async function resetInspection() {
            const response = await axiosClient
                .put(`/inspections/${inspection.id}`, {
                    audit_account_status: inspection.audit_account_status,
                    audit_policy_number: inspection.audit_policy_number,
                    audit_policy_holder: inspection.audit_policy_holder,
                    audit_contact_name: inspection.audit_contact_name,
                    audit_contact_number: inspection.audit_contact_number,
                    audit_broker_name: inspection.audit_broker_name,
                    audit_broker_contact_number:
                        inspection.audit_broker_contact_number,
                    audit_broker_email: inspection.audit_broker_email,
                    audit_insurer_email: inspection.audit_insurer_email,
                    audit_completed: 0,
                    init_email_sent: 0,
                    reminder_days: null,
                })
                .then((response) => {
                    // Loop through requirementsArray and set each requirement to completed
                    requirements.forEach((requirement) => {
                        axiosClient
                            .put(
                                `/inspections/requirements/${requirement.id}`,
                                {
                                    requirement_status: "Not selected",
                                    checked: 0,
                                    expected_date: null,
                                    file_path: null,
                                }
                            )
                            .then((response) => {})
                            .catch((error) => {
                                console.log("ERROR: ", error);
                            });
                    });
                    // setAlertMessage("Survey have been reset and available on loaded surveys!");
                    // console.log("Archiving....msg: ", alertMessage);
                    navigate("/load_surveys");
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
        resetInspection();
    };

    const sendEmailConfirm = () => {
        // console.log("LOgo Url: ", logoURL);
        // console.log("QUERRIED R: ", queriedRequirements.length);
        // console.log("Email Inspection: ", inspection);
        // console.log("Email Requirements: ", requirements);
        // console.log("Query Date: ", queryDate);
        // return;
        // Prepare data for sending email
        // console.log("INsurerDetails: ", insurer);
        const data = {
            from_insurer_email: inspection.audit_insurer_email,
            insurer_name: inspection.audit_insurer_name,
            to_broker_email: inspection.audit_broker_email,
            broker_name: inspection.audit_broker_name,
            policy_number: inspection.audit_policy_number,
            policy_holder: inspection.audit_policy_holder,
            contact_name: inspection.audit_contact_name,
            contact_number: inspection.audit_contact_number,
            survey_date: inspection.audit_survey_date,
            surveyor_name: inspection.audit_surveyor_name,
            survey_link: inspection.audit_web_report_link,
            audit_broker_name: inspection.audit_broker_name,
            audit_physical_address: inspection.audit_physical_address,
            cc_emails: inspection.cc_emails,
        };
        // console.log("DATA: ", data);
        // return;
        // Create html filename using data.to_broker_email and unix timestamp
        let filename =
            data.to_broker_email.substring(
                0,
                data.to_broker_email.indexOf("@")
            ) +
            "_" +
            Date.now() +
            ".html";
        // console.log("Filename: ", filename);
        // console.log("NewData: ", data);

        let count = 1;

        let head =
            "<!DOCTYPE html>" +
            "<html>" +
            "<head>" +
            "<title>Occusurv Portal</title>" +
            "<style>" +
            "table { " +
            "font-family: arial, sans-serif; " +
            "border-collapse: collapse; " +
            "width: 100%; " +
            "} " +
            "th { " +
            "background-color: " +
            hexColor +
            "; " +
            "color: white; " +
            "} " +
            "td, th { " +
            "border: 1px solid " +
            hexColor +
            "; " +
            "text-align: left; " +
            "padding: 8px; " +
            "} " +
            "body { " +
            "margin: 10px 30px 10px 30px; " +
            "} " +
            "</style> " +
            "</head>" +
            "<body>" +
            '<div style="text-align: center;"> ' +
            // '<a href="https://portal.occusurv.co.za/api/public/mirror/' +
            '<a href="' +
            base_url +
            "mirror/" +
            filename +
            '">View this email in your browser</a>' +
            "<br /> <small><i>Viewing this email in your browser is highly recommended for full interactivity when uploading files</i></small>" +
            "</div>" +
            "<br /><br />";

        let footer = "</body>" + "</html>";

        let body =
            head +
            // '<img src="https://portal.occusurv.co.za/api/public/' +
            '<img src="' +
            base_url +
            logoURL +
            '" alt="LOGO" width="20%" height="20%" style="display: block;margin-right: auto;">' +
            '<h4 style="font-weight:normal;"> ' +
            "Dear " +
            data.to_broker_email +
            ",<br /><br />" +
            "This serves as a reminder to update the status of the outstanding requirements for the following survey:" +
            "<br /><br />" +
            "Policy Number: " +
            data.policy_number +
            "<br />" +
            "Policyholder: " +
            data.policy_holder +
            "<br />" +
            "Survey Date: " +
            prettyDate(data.survey_date) +
            "<br />" +
            "Broker: " +
            data.audit_broker_name +
            "<br />" +
            "Risk Address: " +
            data.audit_physical_address +
            "<br /><br />";

        let tr_tbl1 = "";
        if (queriedRequirements.length > 0) {
            body +=
                "<strong>Your previous request for extension/queries are as follows:</strong>" +
                "<br /> <br />" +
                '<table border="1" style="border-collapse: collapse;">' +
                "<thead>" +
                "<tr>" +
                "<th>Requirement</th>" +
                "<th>Queries and Responses</th>" +
                "</tr>" +
                "</thead>" +
                "<tbody>";
            queriedRequirements.forEach((requirement) => {
                tr_tbl1 +=
                    "<tr>" +
                    "<td>" +
                    requirement.requirement_details +
                    "</td>" +
                    "<td>";
                forEach(requirement.requirement_queries, (query) => {
                    if (query.from_email == user.email) {
                        tr_tbl1 +=
                            "<strong>UNDERWRITER RESPONSE </strong>(" +
                            prettyDate(query.updated_at) +
                            "): " +
                            query.query_details +
                            "<br />";
                    } else {
                        tr_tbl1 +=
                            "<strong>BROKER QUERY </strong>(" +
                            prettyDate(query.updated_at) +
                            "): " +
                            query.query_details +
                            "<br />";
                    }
                });
                // Add the latest response to the query
                if (queryText != "") {
                    if (requirementID == requirement.requirement_id) {
                        tr_tbl1 +=
                            "<strong>UNDERWRITER RESPONSE </strong> (" +
                            prettyDate(new Date().toISOString().slice(0, 10)) +
                            "): " +
                            queryText +
                            "<br />";
                    }
                }
                tr_tbl1 += "</td>" + "</tr>";
            });
        }
        body += tr_tbl1 + "</tbody>" + "</table>" + "<br /> <br />";
        if (deleteFile.reason.trim() !== "") {
            body +=
                "<strong style='color: red;'>Please note that file attachment for the following requirement was deleted and will not be included in future email reminders:</strong> <br/>" +
                "<strong>Requirement:</strong> " +
                deleteFile.requirement +
                "<br/>" +
                "<strong>Reason:</strong> " +
                deleteFile.reason +
                "<br/>" +
                "<strong>File link:</strong> <a href='" +
                base_url +
                deleteFileRequirement.file_path +
                "' target='_blank'>click here</a><br/><br/>";
        }
        if (resetRequirement.reason.trim() !== "") {
            body +=
                "<strong style='color: red;'>Please note that the following requirement was reset to pending:</strong> <br/>" +
                "<strong>Requirement:</strong> " +
                resetRequirement.requirement +
                "<br/>" +
                "<strong>Reason:</strong> " +
                resetRequirement.reason +
                "<br/><br/>";
        }
        if (Object.keys(editDates).length > 0) {
            body +=
                "<strong style='color: red;'>Please note that some requirements expected completion dates have been changed.</strong><br/>";
        }
        if (overdueReq) {
            body +=
                "<strong style='color: red;'>Some requirements are overdue. Please attend to them as soon as possible.</strong><br/>";
        }
        body +=
            "<br />" +
            "<strong>Please update the applicable status of each requirement from the list below:</strong> " +
            "<br /> <br />" +
            // '<form action="https://portal.occusurv.co.za/api/public/api/inspections/update_requirements" method="post" enctype="multipart/form-data">' +
            '<form action="' +
            base_url +
            'api/inspections/update_requirements"' +
            ' method="post" enctype="multipart/form-data">' +
            '<input type="hidden" name="audit_id" value="' +
            inspection.audit_id +
            '"></input>' +
            '<input type="hidden" name="insurer_id" value="' +
            user.insurer_id +
            '"></input>' +
            '<input type="hidden" name="broker_email" value="' +
            inspection.audit_broker_email +
            '"></input>' +
            '<input type="hidden" name="html_filename" value="' +
            filename +
            '"></input>' +
            '<table border="1" style="border-collapse: collapse;">' +
            "<thead>" +
            "<tr>" +
            "<th>No</th>" +
            "<th>Requirements</th>" +
            "<th>CompleteBy</th>" +
            "<th>Images</th>" +
            "<th>Update Status</th>" +
            "<th>Supporting File</th>" +
            "<th>Extension/Query</th>" +
            "</tr>" +
            "</thead>" +
            "<tbody>";

        let tr = "";
        requirements.forEach((requirement) => {
            if (requirement.checked === 1) {
                tr +=
                    "<tr>" +
                    "<td>" +
                    count +
                    "</td>" +
                    "<td>" +
                    requirement.requirement_details +
                    "</td>" +
                    "<td>";
                if (requirement.id == requirementID) {
                    if (
                        queryDate === "" ||
                        queryDate === null ||
                        queryDate === undefined
                    ) {
                        tr += requirement.expected_date;
                    } else {
                        tr += queryDate;
                    }
                } else {
                    tr += requirement.expected_date;
                }
                tr += "</td>" + "<td>";
                if (requirement.images.length > 0) {
                    requirement.images.forEach((image_path, index) => {
                        tr +=
                            "<p><a href='" +
                            base_url +
                            image_path["image_path"] +
                            "' target='_blank'>Image" +
                            (index + 1) +
                            "</a></p>";
                    });
                }
                tr +=
                    "</td>" +
                    "<td>" +
                    '<select name="status_' +
                    requirement.id +
                    '"';
                if (requirement.requirement_status == "Completed") {
                    tr += " disabled";
                }
                if (requirement.requirement_status == "Waived") {
                    tr += " disabled";
                }
                tr +=
                    ">" +
                    '<option value="' +
                    requirement.requirement_status +
                    '">' +
                    requirement.requirement_status +
                    "</option>" +
                    '<option disabled="disabled">----------</option>' +
                    '<option value="In progress">In progress</option>' +
                    '<option value="To be attended">To be attended</option>' +
                    '<option value="Completed">Completed</option>' +
                    "</select>" +
                    "</td>" +
                    "<td>";

                if (requirement.requirement_status == "Completed") {
                    if (requirement.file_path != null) {
                        requirement.file_path.forEach((filePath, index) => {
                            tr +=
                                "<p><a href='" +
                                base_url +
                                filePath +
                                "' target='_blank'>Link to file " +
                                (index + 1) +
                                "</a></p>";
                        });
                    } else {
                        tr +=
                            '<input type="file" name="filename_' +
                            requirement.id +
                            "_" +
                            inspection.audit_id +
                            '[]" multiple onchange="addNewFileInput(this)"> <h6>20MB max</h6>';
                    }
                } else {
                    tr +=
                        '<input type="file" name="filename_' +
                        requirement.id +
                        "_" +
                        inspection.audit_id +
                        '[]" multiple onchange="addNewFileInput(this)"> <h6>20MB max</h6>';
                    // If any files attached, show the link to the file
                    if (requirement.file_path != null) {
                        requirement.file_path.forEach((filePath, index) => {
                            tr +=
                                "<p><a href='" +
                                base_url +
                                filePath +
                                "' target='_blank'>Link to file " +
                                (index + 1) +
                                "</a></p>";
                        });
                    }
                }
                tr +=
                    "<script>" +
                    "function addNewFileInput(inputElement) {" +
                    "const newFileInput = document.createElement('input');" +
                    "newFileInput.type = 'file';" +
                    "newFileInput.name = inputElement.name;" +
                    "newFileInput.multiple = true;" +
                    "newFileInput.onchange = function() { addNewFileInput(this); };" +
                    "inputElement.parentElement.appendChild(newFileInput);" +
                    "}" +
                    "</script>";
                tr +=
                    "</td>" +
                    "<td>" +
                    '<textarea name="query_' +
                    requirement.id +
                    '" rows="3" cols="30" placeholder="Type here to request an extension or query this requirement"></textarea>' +
                    "</td>" +
                    "</tr>";
                count++;
            }
        });

        body += tr + "</tbody>" + "</table>" + "<br />";
        if (recommendations.length > 0) {
            body += "<strong>Survey Recommendations:</strong><br/>";
            recommendations.forEach((recommendation, index) => {
                body +=
                    index +
                    1 +
                    "." +
                    recommendation.recommendation_details +
                    "<br />";
            });
        }
        body += "<br/>";
        if (underwriterNotes != "") {
            body +=
                "<strong>Memorandum:</strong><br/>" +
                underwriterNotes +
                "<br/>";
        }
        body +=
            "<br /> <br />" +
            "<p>Thank you for your cooperation.</p>" +
            "<br />" +
            '<script type="text/javascript">';
        // Getting ip address
        let ip = "";
        fetch("https://api.ipify.org?format=json")
            .then((response) => response.json())
            .then((data) => {
                ip = data.ip;
                // console.log("IP: ", ip);
            })
            .catch((error) => console.log(error));

        body +=
            "</script>" +
            '<input type="hidden" name="ip_address" value="' +
            ip +
            '"></input> ' +
            "<input " +
            'style="background-color: ' +
            hexColor +
            ';border: none;color: white;padding: 12px 24px;cursor: pointer;font-weight: bold;font-size: 16px;"' +
            'type="submit" value="Submit"> ' +
            "</input>" +
            "</form>" +
            "<br/><br/>" +
            "Sincerely," +
            "<br />" +
            data.insurer_name +
            "</h4>" +
            footer;
        // console.log("Body: ", body);
        // return;

        // Make a POST request to send email
        let overD = "";
        if (overdueReq) overD = "[Overdue Requirements] ";
        axiosClient
            .post(
                "/send_email",
                // Create a json object with the data to send
                {
                    from_email: insurer["email_address"],
                    from_name: insurer["email_name"],
                    recipient_email: data?.to_broker_email,
                    // cc_email: data.from_insurer_email,
                    cc_email:
                        data?.cc_emails?.length > 0
                            ? [...(data?.cc_emails ?? []), user?.email, portal_email]
                            : [user?.email, portal_email],
                    subject:
                        overD +
                        "Reminder to complete the survey requirements - " +
                        data.policy_holder,
                    body: body,
                    filename: filename,
                }
            )
            .then((response) => {
                setSnackBarOpen(true);
                // console.log("Response: ", response);
                // Record log
                axiosClient
                    .post("/audit_trails", {
                        user_id: user.id.toString(),
                        insurer_id: user.insurer_id,
                        inspection_id: inspection.id,
                        ip_address: ipAddress,
                        title: "Manual Reminder Email",
                        description: `${user.name}(${user.email}) have sent email reminder to broker (${data.broker_name}) to update the status of the requirements for the survey: 
                        Policy Number: ${data.policy_number}, Policyholder: ${data.policy_holder}.`,
                    })
                    .then((response) => {})
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const checkAndUpdateInspection = async () => {
        // console.log("Requirements: ", requirements);
        const allCheckedRequirementsCompleted = requirements
        .filter((requirement) => requirement.checked === 1)
        .every((requirement) => requirement.requirement_status === "Completed");

        if (allCheckedRequirementsCompleted) {
            try {
                const response = await axiosClient.put(`/inspections/${inspectionID}`, {
                    audit_account_status: inspection.audit_account_status,
                    audit_policy_number: inspection.audit_policy_number,
                    audit_policy_holder: inspection.audit_policy_holder,
                    audit_contact_name: inspection.audit_contact_name,
                    audit_contact_number: inspection.audit_contact_number,
                    audit_broker_name: inspection.audit_broker_name,
                    audit_broker_contact_number: inspection.audit_broker_contact_number,
                    audit_broker_email: inspection.audit_broker_email,
                    audit_insurer_email: inspection.audit_insurer_email,
                    audit_completed: 1, // modify this to 1
                    init_email_sent: inspection.init_email_sent,
                    reminder_days: inspection.reminder_days,
                    reminder_start_date: inspection.reminder_start_date,
                    cc_emails: inspection.cc_emails,
                });
                // console.log("Inspection updated successfully:", response.data);
            } catch (error) {
                console.error("Error updating inspection:", error);
            }
        }
    };

    const handleReqSubmit = (event) => {
        event.preventDefault();
        // console.log("Query Date: ", queryDate);
        // console.log("Query Text: ", queryText);
        // console.log("Waiver: ", waiverChecked);
        // return;
        // Reset the reply success and failed
        setReplySuccess(false);
        setReplyFailed(false);
        // Update the completion date of the requirement
        if (queryDate) {
            async function updateCompletionDate() {
                const response = await axiosClient
                    .put(`/inspections/requirements/${requirementID}`, {
                        expected_date: queryDate,
                    })
                    .then((response) => {
                        // Now that expected_date is changed on DB, update it on requirements array
                        forEach(requirements, (requirement) => {
                            if (requirement.id == requirementID) {
                                requirement.expected_date = queryDate;
                            }
                        });
                        
                    })
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            }
            updateCompletionDate();
        }

        // Update the status(waiver) of the requirement
        if (waiverChecked === true) {
            async function updateStatusWaiver() {
                const response = await axiosClient
                    .put(`/inspections/requirements/${requirementID}`, {
                        requirement_status: "Waived",
                        checked: 0,
                    })
                    .then((response) => {
                        // Now that requirement status is changed on DB, update it on requirements array
                        forEach(requirements, (requirement) => {
                            if (requirement.id == requirementID) {
                                requirement.requirement_status = "Waived";
                                requirement.checked = 0;
                            }
                        });
                        setRequirements(requirements);
                        checkAndUpdateInspection();
                    })
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            }
            updateStatusWaiver();
        }
        // Insert a new query response
        if (queryText) {
            const query = {
                requirement_id: requirementID,
                audit_id: auditID,
                from_email: user.email,
                to_email: brokerEmail,
                query_details: queryText,
            };
            // console.log("Query: ", query);
            async function createQuery() {
                const response = await axiosClient
                    .post("/requirement_query", query)
                    .then((response) => {
                        // console.log("RESPONSE: ", response);
                        if (response.status === 201) {
                            setReplySuccess(true);
                        } else {
                            setReplyFailed(true);
                            console.log(
                                "Error while creating a new query user!"
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            createQuery();

            // Sent email to broker, by calling the sendEmail function
            sendEmailConfirm();
        }

        // setOpenDialog(false);
        // reset the query text and date
        setQueryText("");
        setQueryDate("");
    };
    const handleWaiverChange = (event) => {
        setWaiverChecked(event.target.checked);
    };

    const handleEditSubmit = (event) => {
        // console.log("Edit Inspection: ", inspectionEdit);
        if (Object.keys(inspectionEdit).length > 0) {
            async function updateInspection() {
                const response = await axiosClient
                    .put(`/inspections/${inspectionEdit.id}`, {
                        audit_policy_number: inspectionEdit.audit_policy_number,
                        audit_policy_holder: inspectionEdit.audit_policy_holder,
                        audit_contact_name: inspectionEdit.audit_contact_name,
                        audit_contact_number:
                            inspectionEdit.audit_contact_number,
                        audit_broker_name: inspectionEdit.audit_broker_name,
                        audit_broker_contact_number:
                            inspectionEdit.audit_broker_contact_number,
                        audit_broker_email: inspectionEdit.audit_broker_email,
                        audit_insurer_email: inspectionEdit.audit_insurer_email,
                        reminder_days: inspectionEdit.reminder_days,
                        // Same as before, cannot be updated
                        audit_account_status: inspection.audit_account_status,
                        audit_completed: inspection.audit_completed,
                        init_email_sent: inspection.init_email_sent,
                        reminder_start_date: inspection.reminder_start_date,
                    })
                    .then((response) => {
                        // console.log("Response: ", response.data);
                        setInspection(response.data);
                        setOpenEditDialog(false);
                        setSnackBarMessage("Survey updated successfully!");
                        setSnackBarOpen(true);
                    })
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            }
            updateInspection();
        }
    };

    const handleEscalationSubmit = (event) => {
        // console.log("Escalate Survey: ", escalateSurvey);
        async function updateEscalation() {
            const response = await axiosClient
                .put(`/inspections/escalation_status/${inspection.id}`, {
                    escalation_status: 1,
                })
                .then((response) => {
                    axiosClient
                        .post(`/inspections/store_escalation_details`, {
                            id: escalateSurvey.id,
                            audit_id: escalateSurvey.audit_id,
                            audit_policy_holder: inspection.audit_policy_holder,
                            audit_policy_number: inspection.audit_policy_number,
                            escalation_type: escalateSurvey.escalation_type,
                            escalation_details: escalateSurvey.escalation_details,
                            escalated_by: escalateSurvey.escalated_by,
                        })
                        .then((response) => {
                            // Reload the current inspection
                            fetchDataInspectionID();
                            setOpenAddEscalateDialog(false);
                            setSnackBarMessage("Escalation Added successfully!");
                            setSnackBarOpen(true);
                        })
                        .catch((error) => {
                            console.log("ERROR: ", error);
                        });
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
        updateEscalation();
    };

    // Handle the resetting the completion of a requirement
    const handleCompletedOpen = (requirement_id, requirement_details) => () => {
        setCompletedRequirement({
            requirement_id: requirement_id,
            requirement_details: requirement_details,
        });

        setOpenCompletedDialog(true);
    };
    const handleCompletedClose = () => {
        setOpenCompletedDialog(false);
    };

    const resetCompletedRequirement = () => {
        // console.log("Completed ReqID: ", completedRequriment.requirement_id);
        if (resetRequirement.reason.trim() === "") {
            // Popup alert window and terminate
            window.alert(
                "Please provide a reason for resetting this requirement."
            );
            return;
        }
        // Update requirement status to PENDING
        requirements.forEach((requirement) => {
            if (requirement.id === completedRequriment.requirement_id) {
                requirement.requirement_status = "Pending";
            }
        });

        async function updateRequirement() {
            const response = await axiosClient
                .put(
                    `/inspections/requirements/${completedRequriment.requirement_id}`,
                    {
                        requirement_status: "Pending",
                    }
                )
                .then((response) => {
                    sendEmailConfirm(); // Send email to broker
                    axiosClient
                        .post("/audit_trails", {
                            user_id: user.id.toString(),
                            insurer_id: user.insurer_id,
                            inspection_id: inspection.id,
                            ip_address: ipAddress,
                            title: "Requirement Updated",
                            description: `${user.name}(${user.email}) have reset the COMPLETED requirement (${completedRequriment.requirement_details}) status to PENDING for the survey: 
                            Policyholder: ${inspection.audit_policy_holder}, Policy Number: ${inspection.audit_policy_number}. Reason: ${resetRequirement.reason}`,
                        })
                        .then((response) => {
                            setSnackBarMessage(
                                "Requirement status reset successfully and broker notified on email!"
                            );
                            setSnackBarOpen(true);
                            setOpenCompletedDialog(false);
                        })
                        .catch((error) => {
                            console.log("ERROR: ", error);
                        });
                });
        }
        updateRequirement();
    };

    // Handle the deleting of a requirement file attachment
    const handleDeleteReqFileOpen =
        (requirement_id, requirement_details, file_path) => () => {
            setDeleteFileRequirement({
                requirement_id: requirement_id,
                requirement_details: requirement_details,
                file_path: file_path,
            });

            setOpenDeleteFileDialog(true);
        };
    const handleDeleteReqFileClose = () => {
        setOpenDeleteFileDialog(false);
    };

    const deleteRequirementFile = () => {
        // console.log("Delete ReqFile: ", deleteFileRequirement);
        // console.log("DeleteData: ", deleteFile);
        if (deleteFile.reason.trim() === "") {
            // Popup alert window and terminate
            window.alert(
                "Please provide a reason for deleting this requirement attachment file."
            );
            return;
        }

        // Update requirement to remove the file_path
        let new_file_paths = "";
        requirements.forEach((requirement) => {
            if (requirement.id === deleteFileRequirement.requirement_id) {
                // Remove the matching file path
                requirement.file_path = requirement.file_path.filter(
                    (filePath) => filePath !== deleteFileRequirement.file_path
                );
                // For each element of array requirement.file_path, append to new_file_paths
                requirement.file_path.forEach((filePath) => {
                    new_file_paths += "," + filePath;
                });
            }
        });
        // console.log("NewFiles: ", new_file_paths);
        // Now, update new file paths
        async function updateFilePaths() {
            const response = await axiosClient
                .put(
                    `/inspections/requirements/${deleteFileRequirement.requirement_id}`,
                    {
                        file_path: new_file_paths,
                    }
                )
                .then((response) => {
                    sendEmailConfirm(); // Send email to broker
                    axiosClient
                        .post("/audit_trails", {
                            user_id: user.id.toString(),
                            insurer_id: user.insurer_id,
                            inspection_id: inspection.id,
                            ip_address: ipAddress,
                            title: "Requirement Updated",
                            description: `${user.name}(${user.email}) have DELETED a file for requirement (${deleteFileRequirement.requirement_details}): 
                            Policyholder: ${inspection.audit_policy_holder}, Policy Number: ${inspection.audit_policy_number}. Reason: ${deleteFile.reason}`,
                        })
                        .then((response) => {
                            setSnackBarMessage(
                                "Requirement attachment file deleted successfully and broker notified on email!"
                            );
                            setSnackBarOpen(true);
                            setOpenDeleteFileDialog(false);
                        })
                        .catch((error) => {
                            console.log("ERROR: ", error);
                        });
                });
        }
        updateFilePaths();
    };

    // Handle editing completion dates
    const handleCompletionDatesOpen = () => {
        setEditDates({});
        setOpenEditDatesDialog(true);
    };
    const handleCompletionDatesClose = () => {
        setOpenEditDatesDialog(false);
    };

    const handleCompletionDatesSubmit = () => {
        // console.log("Edits Dates: ", editDates);
        if (Object.keys(editDates).length === 0) {
            // Popup alert window and terminate
            window.alert(
                "You have NOT selected any new expected completion dates."
            );
            return;
        }

        let updatePromises = [];

        // Update the completion dates of the requirements
        for (let requirement_id in editDates) {
            let expected_date = editDates[requirement_id];
            let updatePromise = axiosClient
                .put(`/inspections/requirements/${requirement_id}`, {
                    expected_date: expected_date,
                })
                .then((response) => {
                    // Update the requirent in the requirements array with the editDates expected_date
                    requirements.forEach((requirement) => {
                        if (requirement.id == requirement_id) {
                            requirement.expected_date = expected_date;
                        }
                    });
                    // Record audit log
                    return axiosClient
                        .post("/audit_trails", {
                            user_id: user.id.toString(),
                            insurer_id: user.insurer_id,
                            inspection_id: inspection.id,
                            ip_address: ipAddress,
                            title: "Expected Completion Date Updated",
                            description: `${user.name}(${user.email}) have CHANGED the completion date for the survey requirements: 
                            Policyholder: ${inspection.audit_policy_holder}, Policy Number: ${inspection.audit_policy_number}, to new date: ${expected_date}.`,
                        })
                        .then((response) => {
                            setSnackBarMessage(
                                "Expected completion dates successfully changed and broker notified on email!"
                            );
                            setSnackBarOpen(true);
                            setOpenEditDatesDialog(false);
                        })
                        .catch((error) => {
                            console.log("ERROR: ", error);
                        });
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });

            // Add the promise to the updatePromises array
            updatePromises.push(updatePromise);
        }
        Promise.all(updatePromises).then(() => {
            // Send email to broker with all updated completion dates
            sendEmailConfirm();
        });
    };

    const openResurvey = () => {
        setOpenResurveyDialog(true);
    };
    const handleResurveyClose = () => {
        setOpenResurveyDialog(false);
    };

    const sendRequestResurvey = () => {
        // console.log("Inspection: ", inspection);
        // console.log("To Email: ", resurvey_email);

        // Send email to resurvey_email
        let count = 1;
        let body =
            "<!DOCTYPE html>" +
            "<html>" +
            "<head>" +
            "<title>Occusurv Portal - Resurvey</title>" +
            "<style>" +
            "table { " +
            "font-family: arial, sans-serif; " +
            "border-collapse: collapse; " +
            "width: 100%; " +
            "} " +
            "th { " +
            "background-color: " +
            hexColor +
            "; " +
            "color: white; " +
            "} " +
            "td, th { " +
            "border: 1px solid " +
            hexColor +
            "; " +
            "text-align: left; " +
            "padding: 8px; " +
            "} " +
            "body { " +
            "margin: 10px 30px 10px 30px; " +
            "} " +
            "</style> " +
            "</head>" +
            "<body>" +
            "Hi there," +
            "<br /><br />" +
            "Please resurvey the following survey: <br /> <br />" +
            "Policyholder: " +
            inspection.audit_policy_holder +
            "<br />" +
            "Policy Number: " +
            inspection.audit_policy_number +
            "<br />" +
            "Survey Date: " +
            prettyDate(inspection.audit_survey_date) +
            "<br />" +
            "Surveyor Name: " +
            inspection.audit_surveyor_name +
            "<br />" +
            "Pyhsical Address: " +
            inspection.audit_physical_address +
            "<br />" +
            "<a href='" +
            inspection.audit_google_location +
            "'>Google Maps Link</a>" +
            "<br />" +
            "<a href='" +
            inspection.audit_web_report_link +
            "'>Web Report Link</a>" +
            "<br />" +
            "<br /><br />";

        let tr_tbl1 = "";
        if (queriedRequirements.length > 0) {
            body +=
                "<strong>Request for extension/queries were as follows:</strong>" +
                "<br /> <br />" +
                '<table border="1" style="border-collapse: collapse;">' +
                "<thead>" +
                "<tr>" +
                "<th>Requirement</th>" +
                "<th>Queries and Responses</th>" +
                "</tr>" +
                "</thead>" +
                "<tbody>";
            queriedRequirements.forEach((requirement) => {
                tr_tbl1 +=
                    "<tr>" +
                    "<td>" +
                    requirement.requirement_details +
                    "</td>" +
                    "<td>";
                forEach(requirement.requirement_queries, (query) => {
                    if (query.from_email == user.email) {
                        tr_tbl1 +=
                            "<strong>UNDERWRITER RESPONSE </strong>(" +
                            prettyDate(query.updated_at) +
                            "): " +
                            query.query_details +
                            "<br />";
                    } else {
                        tr_tbl1 +=
                            "<strong>BROKER QUERY </strong>(" +
                            prettyDate(query.updated_at) +
                            "): " +
                            query.query_details +
                            "<br />";
                    }
                });
                // Add the latest response to the query
                if (queryText != "") {
                    if (requirementID == requirement.requirement_id) {
                        tr_tbl1 +=
                            "<strong>UNDERWRITER RESPONSE </strong> (" +
                            prettyDate(new Date().toISOString().slice(0, 10)) +
                            "): " +
                            queryText +
                            "<br />";
                    }
                }
                tr_tbl1 += "</td>" + "</tr>";
            });
        }
        body +=
            tr_tbl1 +
            "</tbody>" +
            "</table>" +
            "<br /> <br />" +
            "<strong>Requirements details were as follows:</strong> <br />" +
            '<table border="1" style="border-collapse: collapse;">' +
            "<thead>" +
            "<tr>" +
            "<th>No</th>" +
            "<th>Requirements</th>" +
            "<th>CompleteBy</th>" +
            "<th>Images</th>" +
            "<th>Status</th>" +
            "<th>Supporting File</th>" +
            "</tr>" +
            "</thead>" +
            "<tbody>";

        let tr = "";
        requirements.forEach((requirement) => {
            tr +=
                "<tr>" +
                "<td>" +
                count +
                "</td>" +
                "<td>" +
                requirement.requirement_details +
                "</td>" +
                "<td>";
            if (requirement.expected_date != null) {
                tr += requirement.expected_date;
            }
            tr += "</td>" + "<td>";
            if (requirement.images.length > 0) {
                requirement.images.forEach((image_path, index) => {
                    tr +=
                        "<p><a href='" +
                        base_url +
                        image_path["image_path"] +
                        "' target='_blank'>Image" +
                        (index + 1) +
                        "</a></p>";
                });
            }
            tr +=
                "</td>" +
                "<td>" +
                requirement.requirement_status +
                "</td>" +
                "<td>";
            if (requirement.requirement_status == "Completed") {
                if (requirement.file_path != null) {
                    requirement.file_path.forEach((filePath, index) => {
                        tr +=
                            "<p><a href='" +
                            base_url +
                            filePath +
                            "' target='_blank'>Link to file " +
                            (index + 1) +
                            "</a></p>";
                    });
                }
            }
            tr += "</tr>";
            count++;
        });
        body += tr + "</tbody>" + "</table>" + "<br />";
        if (recommendations != "" && recommendations != undefined) {
            if (recommendations.length > 0) {
                body += "<strong>Survey Recommendations:</strong><br/>";
                recommendations.forEach((recommendation) => {
                    body += recommendation.recommendation_details + "<br/>";
                });
            }
        }
        body += "Sincerely," + "<br />" + inspection.audit_insurer_name;
        body += "</body>" + "</html>";

        const response = axiosClient
            .post("/send_email", {
                from_email: insurer["email_address"],
                from_name: insurer["email_name"],
                recipient_email: resurvey_email,
                subject: insurer["insurer_name"] + " - Request for resurvey",
                body: body,
            })
            .then((response) => {
                // Archive the inspection by changing audit_account_status=Archive and audit_completed=1 via axios post method
                axiosClient
                    .put(`/inspections/${inspection.id}`, {
                        audit_account_status: "Archive",
                        audit_policy_number: inspection.audit_policy_number,
                        audit_policy_holder: inspection.audit_policy_holder,
                        audit_contact_name: inspection.audit_contact_name,
                        audit_contact_number: inspection.audit_contact_number,
                        audit_broker_name: inspection.audit_broker_name,
                        audit_broker_contact_number:
                            inspection.audit_broker_contact_number,
                        audit_broker_email: inspection.audit_broker_email,
                        audit_insurer_email: inspection.audit_insurer_email,
                        audit_completed: 1,
                        init_email_sent: 0,
                    })
                    .then((response) => {})
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });

                // Create audit trail
                axiosClient
                    .post("/audit_trails", {
                        user_id: user.id.toString(),
                        insurer_id: user.insurer_id,
                        inspection_id: inspection.id,
                        ip_address: ipAddress,
                        title: "Request for Resurvey",
                        description: `${user.name}(${user.email}) have requested for resurvey of the following survey and it was archived: 
                        Policyholder: ${inspection.audit_policy_holder}, Policy Number: ${inspection.audit_policy_number}.`,
                    })
                    .then((response) => {})
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });

                setSnackBarMessage(
                    "Request for resurvey sent to Occusurv successfully!"
                );
                setSnackBarOpen(true);
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });

        setOpenResurveyDialog(false);
    };

    const openArchiveDialogButton = () => {
        setOpenArchiveDialog(true);
    };
    const handleArchiveClose = () => {
        setOpenArchiveDialog(false);
    };

    const sendArchiveSurvey = () => {
        // Archive the inspection by changing audit_account_status=Archive and audit_completed=1 via axios post method
        axiosClient
            .put(`/inspections/${inspection.id}`, {
                audit_account_status: "Archive",
                audit_policy_number: inspection.audit_policy_number,
                audit_policy_holder: inspection.audit_policy_holder,
                audit_contact_name: inspection.audit_contact_name,
                audit_contact_number: inspection.audit_contact_number,
                audit_broker_name: inspection.audit_broker_name,
                audit_broker_contact_number:
                    inspection.audit_broker_contact_number,
                audit_broker_email: inspection.audit_broker_email,
                audit_insurer_email: inspection.audit_insurer_email,
                audit_completed: 1,
                init_email_sent: 0,
            })
            .then((response) => {})
            .catch((error) => {
                console.log("ERROR: ", error);
            });

        // Create audit trail
        axiosClient
            .post("/audit_trails", {
                user_id: user.id.toString(),
                insurer_id: user.insurer_id,
                inspection_id: inspection.id,
                ip_address: ipAddress,
                title: "Archive Survey",
                description: `${user.name}(${user.email}) have archived this survey: 
                Policyholder: ${inspection.audit_policy_holder}, Policy Number: ${inspection.audit_policy_number}, Broker: ${inspection.audit_broker_name}.`,
            })
            .then((response) => {
                setSnackBarMessage(
                    "Survey successfully archived, refresh the page and you'll find it under archived surveys!"
                );
                setSnackBarOpen(true);
                setOpenArchiveDialog(false);
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    return (
        <>
            {inspection && ( // If inspection is not null
                <Grid container spacing={0.5}>
                    <Grid item xs={4}>
                        <Item>
                            <List
                                sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                }}
                            >
                                {inspection && (
                                    <>
                                        {/* Survey Date */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <CalendarMonthIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Survey Date:"
                                                    secondary={prettyDate(
                                                        inspection.audit_survey_date
                                                    )}
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Account Status: */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <ToggleOnIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Account Status:"
                                                    secondary={
                                                        inspection.audit_account_status
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Policy Number */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <FormatListNumberedIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Policy Number:"
                                                    secondary={
                                                        inspection.audit_policy_number
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Policy Holder */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <BusinessIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Policyholder:"
                                                    secondary={
                                                        inspection.audit_policy_holder
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Contact Person Name: */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Contact Person Name:"
                                                    secondary={
                                                        inspection.audit_contact_name
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Contact Person Number: */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <ContactPhoneIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Contact Person Number:"
                                                    secondary={
                                                        inspection.audit_contact_number
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Broker Name */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Broker Name:"
                                                    secondary={
                                                        inspection.audit_broker_name
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Broker Contact Number: */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <ContactPhoneIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Broker Contact Number:"
                                                    secondary={
                                                        inspection.audit_broker_contact_number
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Broker Email Address: */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <ContactMailIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Broker Email:"
                                                    secondary={
                                                        inspection.audit_broker_email
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Insurer Name */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <BusinessIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Insurer Name:"
                                                    secondary={
                                                        inspection.audit_insurer_name
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Insurer Email*/}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <ContactMailIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Insurer Email:"
                                                    secondary={
                                                        inspection.audit_insurer_email
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Physical Address */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <LocationOnIcon />
                                                </Avatar>
                                                <ListItemText
                                                    primary="Physical Address:"
                                                    secondary={
                                                        inspection.audit_physical_address
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                        {/* Links */}
                                        <>
                                            <ListItem
                                                sx={{
                                                    // Copy all contents of listItemStyles using spread operator
                                                    ...listItemStyles,
                                                }}
                                            >
                                                <Avatar>
                                                    <LanguageIcon />
                                                </Avatar>
                                                <ListItemText
                                                    secondary={
                                                        <Typography>
                                                            <Link
                                                                to={
                                                                    inspection.audit_web_report_link
                                                                }
                                                                target="_blank"
                                                            >
                                                                Link to survey
                                                            </Link>
                                                            <br />
                                                            <Link
                                                                to={
                                                                    inspection.audit_google_location
                                                                }
                                                                target="_blank"
                                                            >
                                                                Google maps
                                                                location
                                                            </Link>
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                            {/* Email reminder days*/}
                                            <>
                                                <ListItem
                                                    sx={{
                                                        // Copy all contents of listItemStyles using spread operator
                                                        ...listItemStyles,
                                                    }}
                                                >
                                                    <Avatar>
                                                        <DateRangeIcon />
                                                    </Avatar>
                                                    <ListItemText
                                                        primary="Email reminder:"
                                                        secondary={
                                                            "Email is set to send after every " +
                                                            inspection.reminder_days +
                                                            " days"
                                                        }
                                                    />
                                                </ListItem>

                                                <Divider />
                                            </>

                                            {/* CC emails */}
                                            <>
                                                {inspection.cc_emails &&
                                                    inspection.cc_emails
                                                        .length > 0 && (
                                                        <ListItem
                                                            sx={{
                                                                // Copy all contents of listItemStyles using spread operator
                                                                ...listItemStyles,
                                                            }}
                                                        >
                                                            <Avatar>
                                                                <ContactMailIcon />
                                                            </Avatar>
                                                            <ListItemText
                                                                primary="CC'ed Emails:"
                                                                secondary={inspection.cc_emails.map(
                                                                    (email) => (
                                                                        <Box
                                                                            display="flex"
                                                                            gap={
                                                                                1
                                                                            }
                                                                            key={
                                                                                email
                                                                            }
                                                                        >
                                                                            {
                                                                                email
                                                                            }
                                                                        </Box>
                                                                    )
                                                                )}
                                                            />
                                                        </ListItem>
                                                    )}
                                            </>
                                            <Divider />
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                mt="10px"
                                            >
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={<EditIcon />}
                                                    onClick={
                                                        handleClickOpenEdit
                                                    }
                                                >
                                                    Edit Survey
                                                </Button>
                                                {(currentIndexTab == 0 || currentIndexTab == 1) && (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={
                                                        <EscalatorIcon />
                                                    }
                                                    onClick={
                                                        handleClickAddEscalation
                                                    }
                                                >
                                                    Escalate Survey
                                                </Button>
                                                )}

                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={<EmailIcon />}
                                                    onClick={
                                                        handleEmailSurveyor
                                                    }
                                                >
                                                    Email Surveyor
                                                </Button>
                                            </Box>
                                        </>
                                    </>
                                )}
                            </List>
                            {!inspection && (
                                <Typography>
                                    Search for the survey above to display its
                                    details.
                                </Typography>
                            )}
                        </Item>
                    </Grid>
                    {/* *******************************REQUIREMENTS*********************************** */}
                    <Grid item xs={8}>
                        <Item>
                            {requirements && (
                                <>
                                    <Table
                                        sx={{ minWidth: 450 }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    No.
                                                </TableCell>
                                                <TableCell
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Requirement description
                                                </TableCell>
                                                <TableCell
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Complete_By
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Requirement Status
                                                </TableCell>
                                                <TableCell
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Survey Images
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Uploaded Documents
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {requirements.map((requirement) => (
                                                <TableRow
                                                    // If requirement.checked === 0, set selected="true"
                                                    selected={
                                                        requirement.checked ===
                                                        0
                                                    }
                                                    key={requirement.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {(count = count + 1)}
                                                        {(appendText = "")}
                                                        {/* Loop through requirement_queries array to check if there is any query for this requirement */}
                                                        {requirement_queries &&
                                                            requirement_queries.map(
                                                                (
                                                                    requirement_query
                                                                ) => {
                                                                    if (
                                                                        requirement_query.requirement_id ==
                                                                        requirement.id
                                                                    ) {
                                                                        <Typography>
                                                                            {
                                                                                (appendText =
                                                                                    "?")
                                                                            }
                                                                        </Typography>;
                                                                    }
                                                                }
                                                            )}
                                                        <Box
                                                            fontSize={14}
                                                            fontWeight="bold"
                                                            onClick={handleClickOpen(
                                                                requirement.id,
                                                                requirement.audit_id,
                                                                requirement.requirement_details,
                                                                requirement.expected_date
                                                            )}
                                                            sx={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {appendText}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {
                                                            requirement.requirement_details
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            requirement.expected_date
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            requirement.requirement_status
                                                        }
                                                        {requirement.requirement_status ==
                                                            "Completed" && (
                                                            <Button
                                                                size="small"
                                                                startIcon={
                                                                    <HistoryIcon />
                                                                }
                                                                style={{
                                                                    minWidth:
                                                                        "auto",
                                                                    padding:
                                                                        "1px 0",
                                                                }}
                                                                onClick={handleCompletedOpen(
                                                                    requirement.id,
                                                                    requirement.requirement_details
                                                                )}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {requirement.images.map(
                                                            (image_path, index) => (
                                                                <a
                                                                    key={image_path.image_path}
                                                                    href={
                                                                        base_url +
                                                                        image_path[
                                                                            "image_path"
                                                                        ]
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    <Button
                                                                        variant="text"
                                                                        color="primary"
                                                                        size="small"
                                                                        startIcon={
                                                                            <LinkIcon />
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                "auto",
                                                                            padding:
                                                                                "1px 2px",
                                                                        }}
                                                                    ></Button>
                                                                </a>
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography>
                                                            {requirement.file_path &&
                                                                requirement.file_path.map(
                                                                    (
                                                                        filePath,
                                                                        index
                                                                    ) => (
                                                                        <Box
                                                                            display="flex"
                                                                            gap={
                                                                                1
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <a
                                                                                href={
                                                                                    base_url +
                                                                                    filePath
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <Button
                                                                                    // variant="outlined"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    startIcon={
                                                                                        <DownloadIcon />
                                                                                    }
                                                                                    style={{
                                                                                        minWidth:
                                                                                            "auto",
                                                                                        padding:
                                                                                            "1px 0",
                                                                                    }}
                                                                                >
                                                                                    {/* Display no text */}
                                                                                </Button>
                                                                            </a>
                                                                            <Button
                                                                                // variant="outlined"
                                                                                color="primary"
                                                                                size="small"
                                                                                startIcon={
                                                                                    <DeleteForeverIcon />
                                                                                }
                                                                                style={{
                                                                                    minWidth:
                                                                                        "auto",
                                                                                    padding:
                                                                                        "1px 0",
                                                                                }}
                                                                                onClick={handleDeleteReqFileOpen(
                                                                                    requirement.id,
                                                                                    requirement.requirement_details,
                                                                                    filePath
                                                                                )}
                                                                            >
                                                                                {/* Display no text */}
                                                                            </Button>
                                                                        </Box>
                                                                    )
                                                                )}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Divider />
                                    <Box
                                        p={2}
                                        display="flex"
                                        justifyContent="end"
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            startIcon={<EditIcon />}
                                            onClick={handleCompletionDatesOpen}
                                        >
                                            Completion Dates
                                        </Button>
                                    </Box>
                                    <Divider />

                                    {/********************************** RECOMMENDATIONS *******************************/}
                                    {recommendations && (
                                        <>
                                            <Alert
                                                severity="info"
                                                sx={{
                                                    marginBottom: "5px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Survey recommendations:
                                                </Typography>
                                            </Alert>
                                            <Box
                                                sx={{
                                                    paddingTop: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                <Typography
                                                    textAlign={"start"}
                                                    variant="h6"
                                                    sx={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    {recommendations.map(
                                                        (
                                                            recommendation,
                                                            index
                                                        ) => (
                                                            <div
                                                                key={
                                                                    recommendation.id
                                                                }
                                                            >
                                                                {index + 1}.{" "}
                                                                {
                                                                    recommendation.recommendation_details
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                    {/********************************** UNDERWRITER NOTES *******************************/}
                                    {underwriterNotes && (
                                        <>
                                            <Alert
                                                severity="info"
                                                sx={{
                                                    marginBottom: "5px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Initial Memorandum notes to
                                                    broker:
                                                </Typography>
                                            </Alert>
                                            <Box
                                                sx={{
                                                    paddingTop: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                <Typography
                                                    textAlign={"start"}
                                                    variant="h6"
                                                    sx={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: underwriterNotes.replace(
                                                            /\n/g,
                                                            "<br />"
                                                        ),
                                                    }}
                                                ></Typography>
                                            </Box>
                                        </>
                                    )}
                                    <Divider />

                                    {/********************************** ESCALATION DETAILS *******************************/}
                                    {Array.isArray(inspection.escalation_details) && inspection.escalation_details.length > 0 && (
                                        inspection.escalation_details && (
                                            <>
                                             <Alert
                                                severity="info"
                                                sx={{
                                                    marginBottom: "5px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Escalation details
                                                </Typography>
                                            </Alert>
                                            <Table
                                                sx={{ minWidth: 450 }}
                                                aria-label="simple table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Date
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Escalated_By
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Escalation_Type
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Escalation Details
                                                        </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                <TableBody>
                                                {inspection.escalation_details.map((escalation) => (
                                                    <TableRow
                                                        key={escalation.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {prettyDate(escalation.created_at)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {escalation.escalated_by}
                                                        </TableCell>
                                                        <TableCell>
                                                            {escalation.escalation_type}
                                                        </TableCell>
                                                        <TableCell>
                                                            {escalation.escalation_details}
                                                        </TableCell>
                                                    </TableRow>
                                                    ))}
                                                </TableBody>
                                                </Table>
                                                </>
                                            )
                                        )}
                                    <Divider />
                                    {/* *************************************** BUTTONS ************************************* */}
                                    {/* If No requirements, Hide Email button */}
                                    {/* Fo Incomplete surveys */}
                                    {(currentIndexTab == 0 || currentIndexTab == 1 ||
                                        currentIndexTab == 2) &&
                                        requirements.length > 0 && (
                                            <Box
                                                display="flex"
                                                justifyContent="end"
                                                m="10px"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<EmailIcon />}
                                                    onClick={sendEmailConfirm}
                                                >
                                                    Send Reminder Email
                                                </Button>
                                            </Box>
                                        )}
                                    {/* Fo Incomplete surveys */}
                                    {(currentIndexTab == 0 || currentIndexTab == 1 ||
                                        currentIndexTab == 2) &&
                                        requirements.length > 0 && (
                                            <Box
                                                display="flex"
                                                justifyContent="end"
                                                m="10px"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={
                                                        <ArchiveOutlined />
                                                    }
                                                    onClick={
                                                        openArchiveDialogButton
                                                    }
                                                >
                                                    Archive Survey
                                                </Button>
                                            </Box>
                                        )}
                                    {/* If Tab is Completed Surveys */}
                                    {currentIndexTab == 3 &&
                                        requirements.length > 0 && (
                                            <Box
                                                display="flex"
                                                justifyContent="end"
                                                m="10px"
                                            >
                                                <PDFDownloadLink
                                                    document={
                                                        <ViewPDF
                                                            inspection={
                                                                inspection
                                                            }
                                                            requirements={
                                                                requirements
                                                            }
                                                        />
                                                    }
                                                    fileName={
                                                        inspection.audit_policy_number +
                                                        "_requirements.pdf"
                                                    }
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        startIcon={
                                                            <PictureAsPdfIcon />
                                                        }
                                                    >
                                                        Download
                                                    </Button>
                                                </PDFDownloadLink>
                                            </Box>
                                        )}
                                    {/* If Tab is Archived Surveys, have reset button to reset survey to appear on loaded */}
                                    {currentIndexTab == 4 && (
                                        <Box
                                            display="flex"
                                            justifyContent="end"
                                            m="10px"
                                        >
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                startIcon={<ReplayIcon />}
                                                onClick={resetSurvey}
                                            >
                                                Reopen Survey
                                            </Button>
                                        </Box>
                                    )}
                                    {/* If Tab is Archived or Fetched Surveys, have delete button to delete survey if not needed */}
                                    {((//currentIndexTab != 3 &&
                                        user?.role === "Admin") ||
                                        currentIndexTab == 4) && (
                                        <Box
                                            display="flex"
                                            justifyContent="end"
                                            m="10px"
                                        >
                                            <Button
                                                variant="outlined"
                                                color="warning"
                                                size="small"
                                                startIcon={
                                                    <DeleteForeverIcon />
                                                }
                                                onClick={deleteSurvey}
                                            >
                                                Delete Survey
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            )}

                            {/* For all tabs, add resurvey button */}
                            <Box display="flex" justifyContent="end" m="10px">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    startIcon={<EmailIcon />}
                                    onClick={openResurvey}
                                >
                                    Request Resurvey
                                </Button>
                            </Box>

                            {/* If No requirements available */}
                            {(!requirements || requirements.length === 0) && (
                                <Typography>
                                    No requirements found for this survey
                                </Typography>
                            )}
                        </Item>
                    </Grid>
                    {/* *************************************************QUERY DIALOG*********************************************** */}
                    <div>
                        <Dialog
                            open={openDialog}
                            onClose={handleClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="md"
                            fullWidth={true}
                        >
                            <DialogTitle id="scroll-dialog-title">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Query or extension request thread for this
                                    requirement
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText id="scroll-dialog-description">
                                    <List
                                        sx={{
                                            width: "100%",
                                            bgcolor: "background.paper",
                                        }}
                                    >
                                        <ListItemText>
                                            <Typography>
                                                {currentReq}
                                            </Typography>
                                        </ListItemText>
                                        <Divider />
                                        {/* ******************** Header of thread ********************* */}
                                        <Grid
                                            container
                                            sx={{ marginTop: "10px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Typography
                                                    align="left"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    BROKER
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    align="right"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    ME ({user.email})
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {queries && ( // If queries is not null
                                            <>
                                                {queries.map(
                                                    (
                                                        query // Loop through queries array
                                                    ) => (
                                                        <ListItem
                                                            key={query.id}
                                                        >
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <ListItemText
                                                                        align={
                                                                            query.from_email ==
                                                                            user.email
                                                                                ? "right"
                                                                                : "left"
                                                                        }
                                                                        primary={
                                                                            query.from_email !=
                                                                            user.email
                                                                                ? query.from_email +
                                                                                  ": " +
                                                                                  query.query_details
                                                                                : query.query_details
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <ListItemText
                                                                        align={
                                                                            query.from_email ==
                                                                            user.email
                                                                                ? "right"
                                                                                : "left"
                                                                        }
                                                                        secondary={prettyDate(
                                                                            query.query_date
                                                                        )}
                                                                    ></ListItemText>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </List>
                                    <Divider />
                                    <form
                                        autoComplete="off"
                                        onSubmit={handleReqSubmit}
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={7} marginTop={1}>
                                                <TextareaAutosize
                                                    minRows={5}
                                                    style={{ width: "100%" }}
                                                    placeholder="Response to the broker and select new completion date if required."
                                                    required={true}
                                                    onChange={(e) =>
                                                        setQueryText(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DemoContainer
                                                        components={[
                                                            "DatePicker",
                                                            // "DatePicker",
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            label={
                                                                regExpectedDate
                                                            }
                                                            defaultValue={null}
                                                            onChange={(
                                                                newValue
                                                            ) => {
                                                                setQueryDate(
                                                                    dayjs(
                                                                        newValue
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="success"
                                                            onChange={
                                                                handleWaiverChange
                                                            }
                                                        />
                                                    }
                                                    label="Check to apply waiver"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1}
                                                align="right"
                                                marginTop={1}
                                            >
                                                <Fab
                                                    color="primary"
                                                    aria-label="add"
                                                    type="submit"
                                                >
                                                    <SendIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Box
                                        marginTop={2}
                                        marginBottom={2}
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {replyFailed && (
                                            <Alert severity="error">
                                                Something went wrong — please
                                                try again!
                                            </Alert>
                                        )}
                                        {replySuccess && (
                                            <Alert severity="success">
                                                Successfully responded to query
                                                and email sent to the broker!
                                                You can now close this dialog.
                                            </Alert>
                                        )}
                                    </Box>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    {/* *************************************************EDIT SURVEY DIALOG*********************************************** */}
                    <div>
                        <Dialog
                            open={openEditDialog}
                            onClose={handleEditClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="md"
                            fullWidth={true}
                        >
                            <DialogTitle id="scroll-dialog-title">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Edit Survey Details
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText id="scroll-dialog-description">
                                    <form
                                        autoComplete="off"
                                        onSubmit={handleEditSubmit}
                                        sx={{
                                            // width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {/* Form controll with 2 columns which has label (Policy Hplder) and the text field for Policy Holder  */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="policy_number"
                                                    label="Policy Number"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_policy_number
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_policy_number:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="policy_holder"
                                                    label="Policy Holder"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_policy_holder
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_policy_holder:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Contact Person Name"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_contact_name
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_contact_name:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Contact Person Number:"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_contact_number
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_contact_number:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Broker Name:"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_broker_name
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_broker_name:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Broker Contact Number:"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_broker_contact_number
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_broker_contact_number:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Email reminder number of days:"
                                                    variant="standard"
                                                    fullWidth
                                                    type="number"
                                                    defaultValue={
                                                        inspection.reminder_days
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            reminder_days:
                                                                parseInt(
                                                                    e.target
                                                                        .value,
                                                                    10
                                                                ),
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Broker Email:"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_broker_email
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_broker_email:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Underwriter Email:"
                                                    variant="standard"
                                                    fullWidth
                                                    defaultValue={
                                                        inspection.audit_insurer_email
                                                    }
                                                    onChange={(e) =>
                                                        setInspectionEdit({
                                                            ...inspectionEdit,
                                                            audit_insurer_email:
                                                                e.target.value,
                                                        })
                                                    }
                                                    required={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={handleEditSubmit}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleEditClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    {/* *************************************************ADD ESCALATION*********************************************** */}
                    <div>
                        <Dialog
                            open={openAddEscalateDialog}
                            onClose={handleAddEscalateClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="md"
                            fullWidth={true}
                        >
                            <DialogTitle id="scroll-dialog-title">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Survey Escalation
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText id="scroll-dialog-description">
                                    <strong>
                                        Please provide escalation details of the following survey:
                                    </strong>
                                    <br />
                                    Policy Number:{" "}
                                    {inspection.audit_policy_number}
                                    <br />
                                    Policyholder:{" "}
                                    {inspection.audit_policy_holder}
                                    <br />
                                    Escalated by: {user?.name}
                                    <br />
                                </DialogContentText>
                                <Box mt={1}>
                                    <TextField
                                        label="Type the escalation name"
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' }
                                        }}
                                        variant="standard"
                                        fullWidth
                                        onChange={(e) =>
                                           setEscalateSurvey(prevState => ({
                                                ...prevState,
                                                escalation_type: e.target.value
                                            }))
                                        }
                                        sx = {{marginBottom: "15px"}}
                                    />

                                    <typography>
                                        <strong>Escalation Details:</strong>
                                    </typography>
                                    <TextareaAutosize
                                        minRows={6}
                                        style={{ width: "100%" }}
                                        // placeholder="Please provide a reason why this file is deleted."
                                        required={true}
                                        onChange={(e) =>
                                            setEscalateSurvey(prevState => ({
                                                ...prevState,
                                                escalation_details: e.target.value
                                            }))
                                        }
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={handleEscalationSubmit}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleAddEscalateClose}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    {/* ************************************************* EMAIL SURVEYOR DIALOG*********************************************** */}
                    <div>
                        <Dialog
                            open={openEmailSurveyor}
                            onClose={handleEmailSurveyorClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="md"
                            fullWidth={true}
                        >
                            <DialogTitle id="scroll-dialog-title">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Email Surveyor - Occusurv
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText id="scroll-dialog-description">
                                    <strong>
                                        Please type an email message below to
                                        send to the surveyor for the following
                                        survey:
                                    </strong>
                                    <br />
                                    Surveyor: {inspection.audit_surveyor_name}
                                    <br />
                                    Policy Number:{" "}
                                    {inspection.audit_policy_number}
                                    <br />
                                    Policyholder:{" "}
                                    {inspection.audit_policy_holder}
                                    <br />
                                    Broker Name: {inspection.audit_broker_name}
                                    <br />
                                    Physical Address:{" "}
                                    {inspection.audit_physical_address}
                                    <br />
                                </DialogContentText>
                                <Box mt={2}>
                                    <TextareaAutosize
                                        minRows={6}
                                        style={{ width: "100%" }}
                                        // placeholder="Please provide a reason why this file is deleted."
                                        required={true}
                                        onChange={(e) =>
                                            setEmailMessage(e.target.value)
                                        }
                                    />
                                </Box>
                                <DialogContentText>
                                    <i>
                                        <strong>Note:</strong> The surveyor will
                                        respond via email to your email:{" "}
                                        {user?.email}
                                    </i>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={handleEmailSurveyorSubmit}
                                >
                                    Send
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleEmailSurveyorClose}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* ********************* RESET COMPLETED REQUIREMENT TO PENDING DIALOG ************************ */}
                    <div>
                        <Dialog
                            open={openCompletedDialog}
                            onClose={handleCompletedClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {
                                    "This action will reset the following 'Completed' requirement status to 'Pending'. Are you sure?"
                                }
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {completedRequriment.requirement_details}
                                </DialogContentText>
                                <Box mt={2}>
                                    <TextareaAutosize
                                        minRows={5}
                                        style={{ width: "100%" }}
                                        placeholder="Please provide a reason why this requirement is reset."
                                        required={true}
                                        onChange={(e) =>
                                            setResetRequirement({
                                                reason: e.target.value,
                                                requirement:
                                                    completedRequriment.requirement_details,
                                            })
                                        }
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={resetCompletedRequirement}
                                    autoFocus
                                >
                                    {" "}
                                    YES
                                </Button>
                                <Button onClick={handleCompletedClose}>
                                    NO
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* ********************* DETELE REQUIREMENT ATTACHMENT FILE DIALOG ************************ */}
                    <div>
                        <Dialog
                            open={openDeleteFileDialog}
                            onClose={handleDeleteReqFileClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {
                                    "Are you sure you want to delete this file attachment for the following requirement?"
                                }
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {deleteFileRequirement.requirement_details}
                                </DialogContentText>
                                <Box mt={2}>
                                    <TextareaAutosize
                                        minRows={5}
                                        style={{ width: "100%" }}
                                        placeholder="Please provide a reason why this file is deleted."
                                        required={true}
                                        onChange={(e) =>
                                            setDeleteFile({
                                                reason: e.target.value,
                                                requirement:
                                                    deleteFileRequirement.requirement_details,
                                            })
                                        }
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={deleteRequirementFile}
                                    autoFocus
                                >
                                    {" "}
                                    YES
                                </Button>
                                <Button onClick={handleDeleteReqFileClose}>
                                    NO
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* ************************** REQUEST RESURVEY DIALOG ************************ */}
                    <div>
                        <Dialog
                            open={openResurveyDialog}
                            onClose={handleResurveyClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                This action will send an email to Occusurv to
                                request a resurvey . Are you sure?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Note that confirming this will send an email
                                    to Occusurv requesting a resurvey. The
                                    current survey will be archived, and the new
                                    resurvey will be handled separately.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={sendRequestResurvey} autoFocus>
                                    {" "}
                                    YES
                                </Button>
                                <Button onClick={handleResurveyClose}>
                                    NO
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* ************************** ARCHIVE SURVEY DIALOG ************************ */}
                    <div>
                        <Dialog
                            open={openArchiveDialog}
                            onClose={handleArchiveClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Archive this survey?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    This action will archive the survey and move
                                    it to archived surveys tab. Scheduled
                                    automated email reminders will stop. If you
                                    need to permanantly delete this survey, you
                                    can do so from the archived surveys tab.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={sendArchiveSurvey} autoFocus>
                                    {" "}
                                    YES
                                </Button>
                                <Button onClick={handleArchiveClose}>NO</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* ************************************************* EDIT COMPLETION DATES DIALOG *********************************************** */}
                    <div>
                        <Dialog
                            open={openEditDatesDialog}
                            onClose={handleCompletionDatesClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="md"
                            fullWidth={true}
                        >
                            <DialogTitle id="scroll-dialog-title">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Edit Expected Completion Dates
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText id="scroll-dialog-description">
                                    <strong>
                                        Update the expected completion dates for
                                        the requirements below:
                                    </strong>
                                </DialogContentText>
                                <Box mt={2}>
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 450 }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Requirement description
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Completion date
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {requirements?.map(
                                                    (requirement) =>
                                                        requirement.checked ===
                                                            1 &&
                                                        requirement.requirement_status !==
                                                            "Completed" && (
                                                            <TableRow
                                                                key={
                                                                    requirement.id
                                                                }
                                                                sx={{
                                                                    "&:last-child td, &:last-child th":
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                }}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <Typography>
                                                                        {
                                                                            requirement.requirement_details
                                                                        }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <Typography>
                                                                        {
                                                                            requirement.requirement_status
                                                                        }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <LocalizationProvider
                                                                        dateAdapter={
                                                                            AdapterDayjs
                                                                        }
                                                                    >
                                                                        <DemoContainer
                                                                            components={[
                                                                                "DatePicker",
                                                                            ]}
                                                                        >
                                                                            <DatePicker
                                                                                label={
                                                                                    requirement.expected_date
                                                                                }
                                                                                defaultValue={
                                                                                    null
                                                                                }
                                                                                onChange={(
                                                                                    newValue
                                                                                ) => {
                                                                                    setEditDates(
                                                                                        {
                                                                                            ...editDates,
                                                                                            [requirement.id]:
                                                                                                dayjs(
                                                                                                    newValue
                                                                                                ).format(
                                                                                                    "YYYY-MM-DD"
                                                                                                ),
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={handleCompletionDatesSubmit}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleCompletionDatesClose}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert
                            onClose={closeSnackbar}
                            severity="success"
                            color="success"
                            sx={{ width: "100%" }}
                        >
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
            )}
        </>
    );
}
