import * as React from "react";
import {
    Box,
    Button,
    Typography,
    useTheme,
    Paper,
    TextField,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    FormControlLabel,
    FormControl,
    Divider,
    TextareaAutosize,
    Chip,
    Tooltip
} from "@mui/material";
import { tokens } from "../../theme";
import axiosClient from "../../axios";
import prettyDate from "../../formatdate";
import { useState, useEffect, useContext, useCallback } from "react";
import { Formik, Field } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";
import UserContext from "../../contexts/UserContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileDropzone from "../../components/FileDropzone";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from "@mui/icons-material/Link";

const NewSurvey = ({ inspection, requirements, recommendations, resetAll, setRequirements }) => {
    // console.log("child INSPECTION: ", inspection);
    // console.log("child INSPECTION ID: ", inspection.id);
    // recommendations = "No recommendations";
    // console.log("child Rquirements: ", requirements);
    let reqCount = 0;
    // const base_url = "http://localhost:8000/";
    const base_url = "https://portal.occusurv.co.za/api/public/";
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const { user } = useContext(UserContext);
    const [insurer, setInsurer] = useState({});
    const [values, setValues] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [reminderDays, setReminderDays] = useState(15);
    const [ipAddress, setIPAddress] = useState("-");
    const [alertMessage, setAlertMessage] = useState("Survey updated!");
    const [logoURL, setLogoURL] = useState("");
    const [hexColor, setHexColor] = useState("#2a2d64");
    const [openImagesDialog, setOpenImagesDialog] = useState(false);
    const [openRecommendationsDialog, setOpenRecommendationsDialog] = useState(false);
    const [scroll, setScroll] = useState("paper");
    const [currentReqID, setCurrentReqID] = useState(null);
    const [currentReqDetails, setCurrentReqDetails] = useState("");
    const [currentReccID, setCurrentReccID] = useState(null);
    const [currentReccDetails, setCurrentReccDetails] = useState("");
    const [files, setFiles] = useState([]);
    const [requirementsImages, setRequirementsImages] = useState({});
    const [reccMessage, setReccMessage] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [ccEmails, setCcEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [emailError, setEmailError] = useState('');

    const portal_email = "portal@occusurv.co.za";

    let initialValues = {
        id: inspection.id,
        audit_id: inspection.audit_id,
        template_id: inspection.template_id,
        audit_created_at: inspection.audit_created_at,
        audit_modified_at: inspection.audit_modified_at,
        audit_surveyor_name: inspection.audit_surveyor_name,
        audit_survey_date: inspection.audit_survey_date,
        audit_account_status: inspection.audit_account_status,
        audit_policy_number: inspection.audit_policy_number,
        audit_policy_holder: inspection.audit_policy_holder,
        audit_contact_name: inspection.audit_contact_name,
        audit_contact_number: inspection.audit_contact_number,
        audit_broker_name: inspection.audit_broker_name,
        audit_broker_contact_number: inspection.audit_broker_contact_number,
        audit_broker_email: inspection.audit_broker_email,
        audit_insurer_name: inspection.audit_insurer_name,
        audit_insurer_email: inspection.audit_insurer_email,
        audit_physical_address: inspection.audit_physical_address,
        audit_google_location: inspection.audit_google_location,
        audit_web_report_link: inspection.audit_web_report_link,
        audit_completed: inspection.audit_completed,
        checked: [],
        expected_dates: {},
        req_images: {},
        notes_text: "",
    };
    // console.log("INITIAL VALUES: ", initialValues);
    const checkoutSchema = yup.object().shape({
        audit_account_status: yup.string().required("required"),
        audit_policy_number: yup.string().required("required"),
        audit_policy_holder: yup.string().required("required"),
        // audit_contact_name: yup.string().required("required"),
        // audit_contact_number: yup.string().required("required"),
        audit_broker_name: yup.string().required("required"),
        // audit_broker_contact_number: yup.string().required("required")
    });

    let requirementsArray = [];
    // Loop through an object of requirements and insert into an array
    Object.values(requirements).forEach((val) => {
        requirementsArray.push(val);
    });
    // console.log("REQ ARRY: ", requirementsArray);

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    // Get IP address of this client
    useEffect(() => {
        fetch("https://api.ipify.org?format=json")
            .then((response) => response.json())
            .then((data) => setIPAddress(data.ip))
            .catch((error) => console.log(error));
    }, []);

    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCheckChange = (event) => {
        setTermsChecked(event.target.checked);
    };

    const handleReminderDaysChange = (event) => {
        setReminderDays(event.target.value);
        // console.log("DAYS: ", reminderDays);
    };
    // Get Logo of the Insurer URL
    useEffect(() => {
        async function getLogoUrl() {
            const response = await axiosClient.get(
                `/insurances/logo_url/${user.insurer_id}`
            );
            if (response.data) {
                setLogoURL(response.data[0]);
            }
        }
        getLogoUrl();
    });

    // Get Hex colour code of the Insurer
    useEffect(() => {
        async function getHexColour() {
            const response = await axiosClient.get(
                `/insurances/hex_colour/${user.insurer_id}`
            );
            if (response.data) {
                setHexColor(response.data[0]);
            }
        }
        getHexColour();
    });

    // Get Insurer details
    useEffect(() => {
        async function getInsurer() {
            const response = await axiosClient.get(
                `/insurer/${user?.insurer_id}`
            );
            if (response.data) {
                // console.log("INSURER: ", response.data);
                setInsurer(response.data);
            }
        }
        getInsurer();
    }, []);

    // Handle ccEmails
    const handleEmailInputChange = (event) => {
        setEmailInput(event.target.value);
        setEmailError(''); // Clear error message when input changes
    };

    const handleEmailInputKeyPress = (event) => {
        if (event.key === 'Enter' && emailInput.trim() !== '') {
            event.preventDefault(); // Prevent form submission
            // Validate email format
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(emailInput.trim())) {
                setEmailError('Invalid email address');
                return;
            }
            setCcEmails([...ccEmails, emailInput.trim()]);
            setEmailInput('');
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setCcEmails(ccEmails.filter(email => email !== emailToDelete));
    };

    // useEffect(() => {
    //     // This effect runs whenever ccEmails changes
    //     console.log('Updated ccEmails:', ccEmails);
    // }, [ccEmails]);

    const handleAgreeButton = () => {
        // console.log('VALUES: ', values);
        // console.log("Days: ", reminderDays);
        // console.log("Terms: ", termsChecked);
        // console.log("CC Emails: ", ccEmails);

        if (values.checked.length > 0) {
            requirementsArray.forEach((requirement) => {
                if (values.checked.includes(requirement.id)) {
                    // Modify requirementsArray to change checked to 1
                    requirement.checked = 1;
                    requirement.requirement_status = "Pending";
                    requirement.expected_date =
                        values.expected_dates[requirement.id];
                }
            });
        }
        // console.log("REQ ARRAY: ", requirementsArray);
        // console.log("EXpected: ", values.expected_dates);
        if (values["notes_text"] != "") {
            // Look for \n and replace with <br/>
            values["notes_text"] = values["notes_text"].replace(
                /\n/g,
                "<br/>"
            );
        }

        async function updateInspection() {
            const response = await axiosClient
                .put(`/inspections/${values["id"]}`, {
                    audit_account_status: values["audit_account_status"],
                    audit_policy_number: values["audit_policy_number"],
                    audit_policy_holder: values["audit_policy_holder"],
                    audit_contact_name: values["audit_contact_name"],
                    audit_contact_number: values["audit_contact_number"],
                    audit_broker_name: values["audit_broker_name"],
                    audit_broker_contact_number:
                        values["audit_broker_contact_number"],
                    audit_broker_email: values["audit_broker_email"],
                    audit_insurer_email: user.email,
                    audit_completed: values["audit_completed"],
                    init_email_sent: 1,
                    reminder_days: reminderDays,
                    reminder_start_date: dayjs().format("YYYY-MM-DD"),
                    ...(ccEmails.length > 0 && { cc_emails: ccEmails }),
                })
                .then((response) => {
                    // Send post request to notes table
                    if (values["notes_text"] != "") {
                        axiosClient
                            .post("/inspections/notes", {
                                audit_id: values["audit_id"],
                                underwriter_notes: values["notes_text"],
                            })
                            .then((response) => {})
                            .catch((error) => {
                                console.log("ERROR: ", error);
                            });
                    }
                    // Loop through each checked requirement and update it
                    if (values.checked.length > 0) {
                        values.checked.forEach((requirement_id) => {
                            axiosClient
                                .put(
                                    `/inspections/requirements/${requirement_id}`,
                                    {
                                        checked: 1,
                                        expected_date:
                                            values.expected_dates[
                                                requirement_id
                                            ],
                                        requirement_status: "Pending",
                                    }
                                )
                                .then((response) => {
                                    // Upload images to the server and save the file path in the database
                                    if (
                                        values.req_images[requirement_id] !=
                                        undefined
                                    ) {
                                        // console.log("REG Files: ", values.req_images[requirement_id]);
                                        values.req_images[
                                            requirement_id
                                        ].forEach((file) => {
                                            const formData = new FormData();
                                            formData.append(
                                                "requirement_id",
                                                requirement_id
                                            );
                                            formData.append("file", file);
                                            async function uploadImages() {
                                                const response =
                                                    await axiosClient
                                                        .post(
                                                            "/requirements_images",
                                                            formData,
                                                            {
                                                                headers: {
                                                                    "Content-Type":
                                                                        "multipart/form-data",
                                                                },
                                                            }
                                                        )
                                                        .then((response) => {})
                                                        .catch((error) => {
                                                            console.log(
                                                                "ERROR: ",
                                                                error
                                                            );
                                                        });
                                            }
                                            uploadImages();
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.log("ERROR: ", error);
                                });
                        });
                    }
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
        updateInspection();

        // Record Audit trail
        async function recordAuditTrail() {
            const response = await axiosClient
                .post("/audit_trails", {
                    user_id: user.id.toString(),
                    insurer_id: user.insurer_id,
                    inspection_id: inspection.id,
                    ip_address: ipAddress,
                    title: "New Survey Uploaded",
                    description: `${user.name}(${
                        user.email
                    }) marked the survey as ACTIVE account. Survey date: ${prettyDate(
                        values["audit_survey_date"]
                    )}, PolicyHolder: ${
                        values["audit_policy_holder"]
                    }, Policy Number: ${values["audit_policy_number"]}. ${
                        values.checked.length
                    } out of ${
                        Object.keys(requirements).length
                    } requirements were selected to be implemented by ${
                        values["audit_broker_name"]
                    }, and an email send to ${values["audit_broker_email"]}`,
                })
                .then((response) => {})
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
        recordAuditTrail();

        // Send an email to the broker
        async function sendEmail() {
            // console.log("REQArray: ", requirementsArray);
            // Loop through requirementsArray and if id of requirement is in values.checked, console.log it
            requirementsArray.forEach((requirement, index) => {
                if (values.checked.includes(requirement.id)) {
                    // console.log("Index: ", index);
                    // console.log("REQUIREMENT: ", requirement.requirement_details);
                }
            });
            // return;

            // Create html filename using data.to_broker_email and unix timestamp
            let filename =
                values["audit_broker_email"].substring(
                    0,
                    values["audit_broker_email"].indexOf("@")
                ) +
                "_" +
                Date.now() +
                ".html";
            let count = 1;

            let head =
                "<!DOCTYPE html>" +
                "<html>" +
                "<head>" +
                "<title>Occusurv Portal</title>" +
                "<style>" +
                "table { " +
                "font-family: arial, sans-serif; " +
                "border-collapse: collapse; " +
                "width: 100%; " +
                "} " +
                "th { " +
                "background-color: " +
                hexColor +
                "; " +
                "color: white; " +
                "} " +
                "td, th { " +
                "border: 1px solid " +
                hexColor +
                "; " +
                "text-align: left; " +
                "padding: 8px; " +
                "} " +
                "body { " +
                "margin: 10px 30px 10px 30px; " +
                "} " +
                "</style> " +
                "</head>" +
                "<body>" +
                '<div style="text-align: center;"> ' +
                // '<a href="https://portal.occusurv.co.za/api/public/mirror/' +
                '<a href="' + base_url + 'mirror/' +
                filename +
                '">View this email in your browser</a>' +
                "<br /> <small><i>Viewing this email in your browser is highly recommended for full interactivity when uploading files</i></small>" +
                "</div>" +
                "<br /><br />";

            let footer = "</body>" + "</html>";

            let body =
                head +
                // '<img src="https://portal.occusurv.co.za/api/public/' +
                '<img src="' + base_url +
                logoURL +
                '" alt="LOGO" width="20%" height="20%" style="display: block;margin-right: auto;">' +
                '<h4 style="font-weight:normal;"> ' +
                "Dear " +
                values["audit_broker_email"] +
                ",<br /><br />" +
                "<strong>Policy Number:</strong> " +
                values["audit_policy_number"] +
                "<br/>" +
                "<strong>Policyholder:</strong> " +
                values["audit_policy_holder"] +
                "<br/>" +
                "<strong>Broker:</strong> " +
                values["audit_broker_name"] +
                "<br/>" +
                "<strong>Risk Address:</strong> " +
                values["audit_physical_address"] +
                "<br/><br/>" +
                "<strong>Survey Requirements:</strong><br/>" +
                "<strong><i>Please accept our appreciation for the time afforded to our surveyor whilst conducting the survey.</i></strong>" +
                "<p>" +
                "As part of our service to our valued clients we make use of our loss prevention team. The purpose of this team is to identify " +
                "areas of concern and provide positive solutions and to reduce various loss exposures, in respect of various classes of business underwritten." +
                "By holistically committing to this program, benefits can be derived for both the Insured and us, your Insurer. Opportunities for risk improvements " +
                "and loss prevention identified during the survey are listed in the Summary of Risk Improvements Opportunities." +
                "We would appreciate your assistance in updating the status and attaching the substantiating documents as per the requirements referenced below, as " +
                "and when they change. Please ensure that these requirements are met by their respective completion dates." +
                "</p><br/>" +
                "<strong>The Risk Improvements are noted as follows:</strong>" +
                "<br/>" +
                // '<form action="https://portal.occusurv.co.za/api/public/api/inspections/update_requirements" method="post" enctype="multipart/form-data">' +
                '<form action="' + base_url + 'api/inspections/update_requirements"' + ' method="post" enctype="multipart/form-data">' +
                '<input type="hidden" name="audit_id" value="' +
                inspection.audit_id +
                '"></input>' +
                '<input type="hidden" name="insurer_id" value="' +
                user.insurer_id +
                '"></input>' +
                '<input type="hidden" name="broker_email" value="' +
                values["audit_broker_email"] +
                '"></input>' +
                '<input type="hidden" name="html_filename" value="' +
                filename +
                '"></input>' +
                '<table border="1" style="border-collapse: collapse;">' +
                "<thead>" +
                "<tr>" +
                "<th>No</th>" +
                "<th>Requirements</th>" +
                "<th>CompleteBy</th>" +
                "<th>Images</th>" +
                "<th>Update Status</th>" +
                "<th>Supporting File</th>" +
                "<th>Extension/Query</th>" +
                "</tr>" +
                "</thead>" +
                "<tbody>";

            let tr = "";
            requirementsArray.forEach((requirement) => {
                if (requirement.checked === 1) {
                    tr +=
                        "<tr>" +
                        "<td>" +
                        count +
                        "</td>" +
                        "<td>" +
                        requirement.requirement_details +
                        "</td>" +
                        "<td>" +
                        requirement.expected_date +
                        "</td>" +
                        "<td>";
                    // If there are images existing, show them
                    if (requirement.images != undefined) {
                        requirement.images.forEach((image, index) => {
                            tr +=
                                "<p><a href='" +
                                base_url +
                                image.image_path +
                                "' target='_blank'>Image" +
                                (index + 1) +
                                "</a></p>";
                        });
                    }
                    // If there are images uploaded, show them
                    if (values.req_images[requirement.id] != undefined) {
                        values.req_images[requirement.id].forEach(
                            (file, index) => {
                                tr +=
                                    "<p><a href='" +
                                    base_url +
                                    "req_images/" +
                                    file.name +
                                    "' target='_blank'>Image" +
                                    (index + 1) +
                                    "</a></p>";
                            }
                        );
                    }
                    tr +=
                        "</td>" +
                        "<td>" +
                        '<select name="status_' +
                        requirement.id +
                        '">' +
                        '<option value="' +
                        requirement.requirement_status +
                        '">' +
                        requirement.requirement_status +
                        "</option>" +
                        '<option disabled="disabled">----------</option>' +
                        '<option value="In progress">In progress</option>' +
                        '<option value="To be attended">To be attended</option>' +
                        '<option value="Completed">Completed</option>' +
                        "</select>" +
                        "</td>" +
                        "<td>";
                        
                        if (requirement.requirement_status == "Completed") {
                            if (requirement.file_path != null) {
                                requirement.file_path.forEach((filePath, index) => {
                                    tr +=
                                        "<p><a href='" +
                                        base_url +
                                        filePath +
                                        "' target='_blank'>Link to file " + (index + 1) + "</a></p>";
                                });
                            } else {
                                tr +=
                                    '<input type="file" name="filename_' +
                                    requirement.id +
                                    "_" +
                                    inspection.audit_id +
                                    '[]" multiple onchange="addNewFileInput(this)"> <h6>20MB max</h6>';
                            }
                        } else {
                            tr +=
                            '<input type="file" name="filename_' +
                            requirement.id +
                            "_" +
                            inspection.audit_id +
                            '[]" multiple onchange="addNewFileInput(this)"> <h6>20MB max</h6>';
                            // If any files attached, show the link to the file
                            if (requirement.file_path != null) {
                                requirement.file_path.forEach((filePath, index) => {
                                    tr +=
                                        "<p><a href='" +
                                        base_url +
                                        filePath +
                                        "' target='_blank'>Link to file " + (index + 1) + "</a></p>";
                                });
                            }
                        }
                    tr +=
                        "<script>" +
                        "function addNewFileInput(inputElement) {" +
                            "const newFileInput = document.createElement('input');" +
                            "newFileInput.type = 'file';" +
                            "newFileInput.name = inputElement.name;" +
                            "newFileInput.multiple = true;" +
                            "newFileInput.onchange = function() { addNewFileInput(this); };" +
                            "inputElement.parentElement.appendChild(newFileInput);" +
                        "}" +
                        "</script>";
                    tr +=
                        "</td>" +
                        "<td>" +
                        '<textarea name="query_' +
                        requirement.id +
                        '" rows="3" cols="30" placeholder="Type here to request an extension or query this requirement"></textarea>' +
                        "</td>" +
                        "</tr>";
                    count++;
                }
            });
            body +=
                tr +
                "</tbody>" +
                "</table>" +
                "<br/>";
            if(recommendations.length > 0) {
                    body += "<strong>Survey Recommendations:</strong><br/>";
                recommendations.forEach((recommendation) => {
                    body += recommendation.recommendation_details + "<br/>";
                });
            }
            body += "<br/>";
            if (values["notes_text"] != "") {
                body +=
                    "<strong>Memorandum:</strong><br/>" +
                    values["notes_text"] +
                    "<br/>";
            }
            body +=
                "<br/>" +
                "<p>" +
                "Please urgently confirm within the abovementioned time period that the risk improvements/requirements have been attended to and met." +
                "The underwriter reserves the right to suspend or restrict cover and/or amend terms or excesses should written confirmation of compliance " +
                "regarding the above requirements not be received within the allocated time frame indicated." +
                "</p>" +
                "<br/>" +
                '<script type="text/javascript">';
            // Getting ip address
            let ip = "";
            fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then((data) => {
                    ip = data.ip;
                    // console.log("IP: ", ip);
                })
                .catch((error) => console.log(error));

            body +=
                "</script>" +
                '<input type="hidden" name="ip_address" value="' +
                ip +
                '"></input> ' +
                "<input " +
                'style= "background-color: ' +
                hexColor +
                ';border: none;color: white;padding: 12px 24px;cursor: pointer;font-weight: bold;font-size: 16px;"' +
                'type="submit" value="Submit"> ' +
                "</input>" +
                "<br/><br/>" +
                "Sincerely,<br />" +
                values["audit_insurer_name"] +
                "</h4>" +
                footer;

            // console.log("BODY: ", body);
            // return;

            const response = await axiosClient
                .post("/send_email", {
                    from_email: insurer['email_address'],
                    from_name: insurer['email_name'],
                    recipient_email: values["audit_broker_email"],
                    // cc_email: user.email,
                    cc_email: ccEmails.length > 0 ? [...ccEmails, user.email, portal_email] : [user.email, portal_email],
                    subject:
                        "Implement requirements for " +
                        values["audit_policy_holder"] +
                        "(" +
                        values["audit_policy_number"] +
                        ")",
                    body: body,
                    filename: filename,
                })
                .then((response) => {})
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
        sendEmail();

        setAlertMessage("Survey updated!");
        setSnackBarOpen(true);
        // Call the resetAll() function passed from parent component
        resetAll();
        setDialogOpen(false);
        return;
    };

    const setReqCount = (count) => {
        reqCount = 1;
    };

    const handleFormSubmit = (values) => {
        // console.log("VALUES: ", values);
        if (values.audit_account_status !== "archive") { // If the survey is not archived
            // Check if values.audit_broker_email is correct format
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(values.audit_broker_email.trim())) {
                alert("Please enter a valid email address for the broker!");
                return;
            }
            // Check if at leat one requirement is selected
            if(values.checked.length === 0) {
                alert("Please select at least one requirement!");
                return;
            }
        }

        // change values.checked to an array of unique numbers
        let unique = [...new Set(values.checked)];
        // remove any strings from the array
        unique = unique.filter((item) => typeof item === "number");
        values.checked = unique;
        // Check whether all the values in the values.checked array exist as keys in the values.expected_dates object
        const allCheckedValuesAreKeysInDates = values.checked.every((value) =>
            values.expected_dates.hasOwnProperty(value)
        );
        if (!allCheckedValuesAreKeysInDates) {
            alert(
                "Please select the expected completion date for each requirement!"
            );
            return;
        }
        // Check if all requirements have been checked for eavery selected date 
        const allDatesKeysAreInChecked = Object.keys(values.expected_dates).every((key) =>
            values.checked.includes(Number(key))
        );
        if (!allDatesKeysAreInChecked) {
            alert(
                "Please check each requirement for every selected completion date!"
            );
            return;
        }

        // Check whether all keys in the requirementsImages dict exist as keys in the values.checked array
        const allImagesKeysAreInCheckedArray = Object.keys(
            requirementsImages
        ).every((key) => values.checked.includes(parseInt(key)));
        if (!allImagesKeysAreInCheckedArray) {
            alert(
                "Please select the corresponding requirement for every set of images upload!"
            );
            return;
        }
        // Add req_images to values
        // console.log("Reg Images: ", requirementsImages);
        values["req_images"] = requirementsImages;
        // Set new values got from form
        setValues(values);

        if (values.audit_account_status === "archive") {
            // This is a Quoted inspection, just mark it as complete and archive it
            values["audit_account_status"] = "Archive";
            values["audit_completed"] = 1;

            async function updateInspection() {
                const response = await axiosClient
                    .put(`/inspections/${values["id"]}`, {
                        audit_account_status: values["audit_account_status"],
                        audit_policy_number: values["audit_policy_number"],
                        audit_policy_holder: values["audit_policy_holder"],
                        audit_contact_name: values["audit_contact_name"],
                        audit_contact_number: values["audit_contact_number"],
                        audit_broker_name: values["audit_broker_name"],
                        audit_broker_contact_number:
                            values["audit_broker_contact_number"],
                        audit_broker_email: values["audit_broker_email"],
                        audit_insurer_email: values["audit_insurer_email"],
                        audit_completed: values["audit_completed"],
                        init_email_sent: 0,
                    })
                    .then((response) => {
                        // Loop through requirementsArray and set each requirement to completed
                        requirementsArray.forEach((requirement) => {
                            axiosClient
                                .put(
                                    `/inspections/requirements/${requirement.id}`,
                                    {
                                        requirement_status: "Archived",
                                    }
                                )
                                .then((response) => {})
                                .catch((error) => {
                                    console.log("ERROR: ", error);
                                });
                        });
                        setAlertMessage("Survey archived!");
                        // console.log("Archiving....msg: ", alertMessage);
                    })
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            }
            updateInspection();

            // Record Audit trail
            async function recordAuditTrail() {
                // console.log("USER: ", user.id.toString());
                const response = await axiosClient
                    .post("/audit_trails", {
                        user_id: user.id.toString(),
                        insurer_id: user.insurer_id,
                        inspection_id: inspection.id,
                        ip_address: ipAddress,
                        title: "New Survey Uploaded",
                        description: `${user.name}(${
                            user.email
                        }) marked the survey as QUOTE. Survey date: ${prettyDate(
                            values["audit_survey_date"]
                        )}, Policyholder: ${
                            values["audit_policy_holder"]
                        }, Policy Number: ${values["audit_policy_number"]}`,
                    })
                    .then((response) => {})
                    .catch((error) => {
                        console.log("ERROR: ", error);
                    });
            }
            recordAuditTrail();
            setSnackBarOpen(true);
            // Call the resetAll() function passed from parent component
            resetAll();
            return;
        } else {
            // This is an active inspection, mark it as complete
            values["audit_account_status"] = "Active";
            // console.log("UPDATE btn VALUES: ", values);
            // Then move control to handleClickDialogOpen()
            handleClickDialogOpen();
        }
    };

    const handleCloseImagesDialog = () => {
        setOpenImagesDialog(false);
    };

    const handleClickImages = (requirement_id, requirement_details) => () => {
        // console.log("Req ID: ", requirement_id);
        setCurrentReqID(requirement_id);
        setCurrentReqDetails(requirement_details);
        setOpenImagesDialog(true);
    };

    const handleUploadButton = () => {
        // console.log("Current Req ID: ", currentReqID);
        // Loop through the files and append currentReqID to the begining of each file name
        let newFiles = [];
        files.forEach((file) => {
            let newFile = new File([file], currentReqID + "_" + file.name, {
                type: file.type,
            });
            newFiles.push(newFile);
        });

        // add the newFiles with setRequirementsImages
        setRequirementsImages({
            ...requirementsImages,
            [currentReqID]: newFiles,
        });
        // console.log("Req Images: ", requirementsImages);

        setOpenImagesDialog(false);
    };

    const changeToRequirement = (recommendation_id, recommendation_details) => () => {
        // console.log("Rec ID: ", recommendation_id);
        // console.log("Rec Details: ", recommendation_details);
        setCurrentReccID(recommendation_id);
        setCurrentReccDetails(recommendation_details);
        setOpenRecommendationsDialog(true);
    };

    const handleCloseRecommendationDialog = () => {
        setOpenRecommendationsDialog(false);
    };

    const handleChangeRecommendation = () => {
        // console.log("Rec ID: ", currentReccID);
        // console.log("Rec Details: ", currentReccDetails);
        // console.log("audit_id: ", inspection.audit_id);

        // Use axios to create a new requirement with the recommendation details following this end point: /inspections/requirements
        async function createRequirement() {
            const response = await axiosClient
                .post("/inspections/requirements", {
                    audit_id: inspection.audit_id,
                    requirement_details: currentReccDetails,
                })
                .then((response) => {
                    // If status code is 201 created, then delete the recommendation
                    if (response.status === 201) {
                        // Make delete request to delete the recommendation using end point: /inspections/recommendations/{id}
                        axiosClient
                            .delete(
                                `/inspections/recommendations/${currentReccID}`
                            )
                            .then((response) => {})
                            .catch((error) => {
                                console.log("DELETE ERROR: ", error);
                            });
                        }
                })
                .catch((error) => {
                    console.log("POST ERROR: ", error);
                });
        }
        createRequirement();

        setDisableButton(true);
        setReccMessage("This recommendation has been converted to a requirement. You will need to refresh the page and select the survey again to see the changes.");
    };

    // Upload Requirement survey images
    const handleUploadReqFiles = (requirement_id) => () => {
        // console.log("Req ID: ", requirement_id);
        // Upload images to the server and save the file path in the database
        if (requirementsImages[requirement_id] != undefined) {
            let uploadCount = 0;
            const totalUploads = requirementsImages[requirement_id].length;
            const updatedRequirements = [...requirements];

            requirementsImages[requirement_id].forEach((file) => {
                const formData = new FormData();
                formData.append("requirement_id", requirement_id);
                formData.append("file", file);
                // uploadImages(formData);
                uploadImages(formData, updatedRequirements, () => {
                    uploadCount++;
                    if (uploadCount === totalUploads) {
                        // All uploads are complete, update the state
                        setRequirements(updatedRequirements);
                    }
                });
            });

            // Alert that attached files uploaded successfully
            window.alert("Files uploaded successfully!");
            setRequirementsImages({});
        }
    };
    const uploadImages = async (formData, updatedRequirements, callback) => {
        await axiosClient
            .post("/requirements_images", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const requirementId = parseInt(response.data.data.requirement_id);
                const imagePath = response.data.data.image_path;
                // console.log("RES data: ", response.data);
                // Update the requirementsArray with the new image path
                updatedRequirements.forEach((req) => {
                    if (req.id === requirementId) {
                        req.images = [...req.images, { image_path: imagePath }];
                    }
                });

                callback();
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    return (
        <Box flex="1 1 100%" ml="15px">
            <Box padding={1}>
                <Typography variant="h5">
                    {Object.keys(inspection).length !== 0 && (
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        marginTop={1}
                                        marginBottom={1}
                                        paddingLeft={1}
                                        paddingBottom={1}
                                        sx={{
                                            bgcolor: colors.primary[400],
                                            borderLeft: "1px solid",
                                            borderRight: "1px solid",
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="h5">
                                                    Insurer:
                                                    {" " +
                                                        values.audit_insurer_name +
                                                        "(" +
                                                        values.audit_insurer_email +
                                                        ")"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5">
                                                    {/* Cretae a href link to report link */}
                                                    <Link
                                                        to={
                                                            values.audit_web_report_link
                                                        }
                                                        target="_blank"
                                                    >
                                                        Survey link
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5">
                                                    {/* Cretae a href link to report link */}
                                                    <Link
                                                        to={
                                                            values.audit_google_location
                                                        }
                                                        target="_blank"
                                                    >
                                                        Google maps location
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="h5">
                                                    Physical address:
                                                    {" " +
                                                        values.audit_physical_address}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5">
                                                    Survey Date:
                                                    {" " +
                                                        prettyDate(
                                                            values.audit_survey_date
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Alert severity="info">
                                        <Typography variant="h5">
                                            Review and edit the information
                                            below to ensure accuracy
                                        </Typography>
                                    </Alert>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        marginTop={1}
                                        sx={{
                                            "& > div": {
                                                gridColumn: isNonMobile
                                                    ? undefined
                                                    : "span 3",
                                            },
                                        }}
                                    >
                                        {/* *********************************************ROW 1 ************************************************** */}
                                        <select
                                            name="audit_account_status"
                                            value={values.audit_account_status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            error={
                                                !!touched.audit_account_status &&
                                                !!errors.audit_account_status
                                            }
                                            helpertext={
                                                touched.audit_account_status &&
                                                errors.audit_account_status
                                            }
                                        >
                                            <option
                                                value=""
                                                label="Select account status"
                                            ></option>
                                            <Divider />
                                            <option
                                                value="active"
                                                label="Active Account"
                                            ></option>
                                            <option
                                                value="accepted"
                                                label="Accepted Quote"
                                            ></option>
                                            {/* <Divider /> */}
                                            <option
                                                value="archive"
                                                label="Archive Survey"
                                            ></option>
                                        </select>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Policy Number:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_policy_number &&
                                                !!errors.audit_policy_number
                                            }
                                            helpertext={
                                                touched.audit_policy_number &&
                                                errors.audit_policy_number
                                            }
                                            value={values.audit_policy_number}
                                            name="audit_policy_number"
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Policyholder:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_policy_holder &&
                                                !!errors.audit_policy_holder
                                            }
                                            helpertext={
                                                touched.audit_policy_holder &&
                                                errors.audit_policy_holder
                                            }
                                            value={values.audit_policy_holder}
                                            name="audit_policy_holder"
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        {/* *********************************************ROW 2************************************************** */}
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Contact Person Name:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_contact_name &&
                                                !!errors.audit_contact_name
                                            }
                                            helpertext={
                                                touched.audit_contact_name &&
                                                errors.firstNaudit_contact_nameame
                                            }
                                            value={values.audit_contact_name}
                                            name="audit_contact_name"
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Contact Person Number:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_contact_number &&
                                                !!errors.audit_contact_number
                                            }
                                            helpertext={
                                                touched.audit_contact_number &&
                                                errors.audit_contact_number
                                            }
                                            value={values.audit_contact_number}
                                            name="audit_contact_number"
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Broker Name:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_broker_name &&
                                                !!errors.audit_broker_name
                                            }
                                            helpertext={
                                                touched.audit_broker_name &&
                                                errors.audit_broker_name
                                            }
                                            value={values.audit_broker_name}
                                            name="audit_broker_name"
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        {/* *********************************************ROW 3 ************************************************** */}
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Broker Contact Number:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_broker_contact_number &&
                                                !!errors.audit_broker_contact_number
                                            }
                                            helpertext={
                                                touched.audit_broker_contact_number &&
                                                errors.audit_broker_contact_number
                                            }
                                            value={
                                                values.audit_broker_contact_number
                                            }
                                            name="audit_broker_contact_number"
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Broker Email:"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                !!touched.audit_broker_email &&
                                                !!errors.audit_broker_email
                                            }
                                            helpertext={
                                                touched.audit_broker_email &&
                                                errors.audit_broker_email
                                            }
                                            value={values.audit_broker_email}
                                            name="audit_broker_email"
                                            sx={{ gridColumn: "span 1" }}
                                            // If values.audit_account_status is not declined, make this field required
                                            required={
                                                values.audit_account_status ===
                                                    "accepted" ||
                                                values.audit_account_status ===
                                                    "active"
                                                    ? true
                                                    : false
                                            }
                                        />

                                        {/* ccEmails */}
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Optional additional correspondence emails (CC'ed)"
                                            value={emailInput}
                                            onChange={handleEmailInputChange}
                                            onKeyPress={handleEmailInputKeyPress}
                                            helperText={emailError || "Press Enter to add email"}
                                            error={!!emailError}
                                            sx={{ gridColumn: "span 2", marginTop: 2 }}
                                        />
                                        <Box sx={{ display: 'flex', gridColumn: "span 2", flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                                            {ccEmails.map((email, index) => (
                                                <Chip
                                                    key={index}
                                                    label={email}
                                                    onDelete={() => handleDeleteEmail(email)}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                    {requirementsArray.length > 0 && (
                                        <Alert
                                            severity="info"
                                            sx={{
                                                marginBottom: "5px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Typography variant="h5">
                                                Please mark the checkboxes next
                                                to the requirements you wish to
                                                have completed
                                            </Typography>
                                        </Alert>
                                    )}
                                    {/* ********************************************* REQUIREMENTS ************************************************** */}
                                    <Box sx={{ paddingTop: "5px" }}>
                                        {requirementsArray.length > 0 && (
                                            <TableContainer component={Paper}>
                                                <Table
                                                    sx={{ minWidth: 450 }}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                No.
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Requirement
                                                                description
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Check
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Completed By
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Images
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {setReqCount(0)}
                                                        {requirementsArray.map(
                                                            (requirement) => (
                                                                <TableRow
                                                                    key={
                                                                        requirement.id
                                                                    }
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th":
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <Typography>
                                                                            {
                                                                                reqCount++
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <Typography>
                                                                            {
                                                                                requirement.requirement_details
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        {/* Create formik check box for each requirement */}
                                                                        {/* <label> */}
                                                                        <Field
                                                                            type="checkbox"
                                                                            name="checked"
                                                                            as={
                                                                                FormControlLabel
                                                                            }
                                                                            control={
                                                                                <Checkbox />
                                                                            }
                                                                            checked={values.checked.includes(
                                                                                requirement.id
                                                                            )}
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                const index =
                                                                                    values.checked.indexOf(
                                                                                        requirement.id
                                                                                    );
                                                                                if (
                                                                                    event
                                                                                        .target
                                                                                        .checked
                                                                                ) {
                                                                                    values.checked.push(
                                                                                        requirement.id
                                                                                    );
                                                                                } else {
                                                                                    values.checked.splice(
                                                                                        index,
                                                                                        1
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        {/* </label> */}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <LocalizationProvider
                                                                            dateAdapter={
                                                                                AdapterDayjs
                                                                            }
                                                                        >
                                                                            <DemoContainer
                                                                                components={[
                                                                                    "DatePicker",
                                                                                    "DatePicker",
                                                                                ]}
                                                                            >
                                                                                <DatePicker
                                                                                    label="Date Picker"
                                                                                    defaultValue={
                                                                                        null
                                                                                    }
                                                                                    onChange={(
                                                                                        newValue
                                                                                    ) => {
                                                                                        values.expected_dates[
                                                                                            requirement.id
                                                                                        ] =
                                                                                            dayjs(
                                                                                                newValue
                                                                                            ).format(
                                                                                                "YYYY-MM-DD"
                                                                                            );
                                                                                    }}
                                                                                    value={
                                                                                        // Get requirement.id from expectedDates object
                                                                                        values
                                                                                            .expected_dates[
                                                                                            requirement
                                                                                                .id
                                                                                        ]
                                                                                            ? values
                                                                                                  .expected_dates[
                                                                                                  requirement
                                                                                                      .id
                                                                                              ]
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        {/* If requirement already has images uploaded */}
                                                                        {requirement.images.map(
                                                                            (image_path, index) => (
                                                                                <a
                                                                                    key={image_path.image_path}
                                                                                    href={
                                                                                        base_url +
                                                                                        image_path[
                                                                                            "image_path"
                                                                                        ]
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <Button
                                                                                        variant="text"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                        startIcon={
                                                                                            <LinkIcon />
                                                                                        }
                                                                                        style={{
                                                                                            minWidth:
                                                                                                "auto",
                                                                                            padding:
                                                                                                "1px 2px",
                                                                                        }}
                                                                                    ></Button>
                                                                                </a>
                                                                            )
                                                                        )}
                                                                        {/* Upload button */}
                                                                        <Box
                                                                            onClick={handleClickImages(
                                                                                requirement.id,
                                                                                requirement.requirement_details
                                                                            )}
                                                                        >
                                                                            {/* If requirementsImages[requirement.id] length > 0 display files attached */}
                                                                            {requirementsImages &&
                                                                                requirementsImages?.[
                                                                                    requirement
                                                                                        .id
                                                                                ] &&
                                                                                Array.isArray(
                                                                                    requirementsImages[
                                                                                        requirement
                                                                                            .id
                                                                                    ]
                                                                                ) && (
                                                                                    <Typography>
                                                                                        {
                                                                                            requirementsImages[
                                                                                                requirement
                                                                                                    .id
                                                                                            ]
                                                                                                .length
                                                                                        }{" "}
                                                                                        files
                                                                                        attached
                                                                                    </Typography>
                                                                                )}
                                                                            <Tooltip title="Upload files for this requirement">
                                                                                <Button startIcon={<FileUploadIcon />} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                        {/* Allow upload of each requirement files */}
                                                                        {user?.role === 'Admin' && requirementsImages?.[requirement.id]?.length > 0 && (
                                                                            <Box
                                                                                onClick={handleUploadReqFiles(requirement.id)}
                                                                            >
                                                                                <Tooltip title="Save files for this requirement">
                                                                                    <Button startIcon={<SaveIcon />} />
                                                                                </Tooltip>
                                                                            </Box>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}

                                        {/* If No requirements available */}
                                        {(!requirementsArray ||
                                            requirementsArray.length === 0) && (
                                            <Typography>
                                                No requirements found for this
                                                inspection
                                            </Typography>
                                        )}
                                    </Box>
                                    {/* *********************************** RECOMMENDATIONS ***************************************** */}
                                    {recommendations.length > 0 && (
                                        <>
                                            <Alert
                                                severity="info"
                                                sx={{
                                                    marginBottom: "5px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    These are the
                                                    recommendations for this
                                                    survey
                                                </Typography>
                                            </Alert>
                                            {/* ******************** DISPLAY RECOMMENDATIONS ****************** */}
                                            <Box
                                                sx={{
                                                    paddingTop: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                             <TableContainer component={Paper}>
                                                <Table
                                                    sx={{ minWidth: 450 }}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Recommendation details
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Change to requirement
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {recommendations.map( (recommendation) => (
                                                            <TableRow
                                                                key={
                                                                    recommendation.id
                                                                }
                                                                sx={{
                                                                    "&:last-child td, &:last-child th":
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                }}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <Typography>
                                                                        {
                                                                            recommendation.recommendation_details
                                                                        }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <Box onClick={changeToRequirement(
                                                                        recommendation.id,
                                                                        recommendation.recommendation_details
                                                                    )} >
                                                                        <Button startIcon={<ArrowCircleUpIcon />} />
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            </Box>
                                        </>
                                    )}
                                    {/* *********************************** UNDERWRITER NOTES ***************************************** */}
                                    <Alert
                                        severity="info"
                                        sx={{
                                            marginBottom: "5px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Memorandum: You can add optional notes here for
                                            the broker
                                        </Typography>
                                    </Alert>
                                    <Box
                                        sx={{
                                            paddingTop: "5px",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                            }}
                                        >
                                            <Field
                                                as={TextareaAutosize}
                                                minRows={5}
                                                style={{ width: "100%" }}
                                                placeholder="If you add optional notes here for the broker to see, they will be sent along the initial email."
                                                name="notes_text"
                                            />
                                        </Typography>
                                    </Box>
                                    <Divider />

                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                        mt="20px"
                                        mb="20px"
                                    >
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    )}
                    {Object.keys(inspection).length === 0 && (
                        <Typography>
                            Choose a survey from the left listing to view its
                            details.
                        </Typography>
                    )}
                </Typography>
            </Box>
            {/* *************************************************Schedule Requirements Dialog********************************************** */}
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" component="div">
                        {
                            "Confirm Agreement to Occusurv’s Terms of Service"
                        }
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Before proceeding, please review and agree to the  
                        <a 
                            href="https://portal.occusurv.co.za/api/public/disclaimer.pdf" target="_blank" rel="noopener noreferrer"> Occusurv Terms of Service
                        </a>.
                        <br /><br />
                        Disclaimer: You are about to send an email to the broker confirming the selected 
                        requirements for implementation. By using the Occusurv portal, you acknowledge 
                        that you are doing so at your own discretion and risk.
                    </DialogContentText>
                    <form>
                        <FormControl>
                            <Input
                                id="component-number"
                                aria-describedby="component-number-text"
                                type="number"
                                value={reminderDays}
                                onChange={handleReminderDaysChange}
                            />
                            <FormHelperText id="component-number-text">
                                After how many days do you want the system to
                                send email reminders to brokers?
                            </FormHelperText>
                        </FormControl>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsChecked}
                                    onChange={handleCheckChange}
                                    color="primary"
                                />
                            }
                            label="I agree to the terms of service and want to schedule email reminders for this survey."
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={handleCloseDialog}
                    >
                        Disagree
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleAgreeButton}
                        disabled={!termsChecked}
                        color="secondary"
                        autoFocus
                    >
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ************************************************* Upload Images Dialog********************************************** */}
            <Dialog
                open={openImagesDialog}
                onClose={handleCloseImagesDialog}
                scroll={scroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Upload images for the following requirement
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    {currentReqDetails}
                    <FileDropzone setFiles={setFiles} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCloseImagesDialog}
                    >
                        Close
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleUploadButton}
                        // disabled={!termsChecked}
                        color="success"
                        autoFocus
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ********************************* Change Recommendation to Requirements Dialog *************************************** */}
            <Dialog
                open={openRecommendationsDialog}
                onClose={handleCloseRecommendationDialog}
                scroll={scroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Are you sure you want to change this recommendation to a requirement? This is irreversible.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText id="alert-dialog-description">
                        {currentReccDetails}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCloseRecommendationDialog}
                    >
                        {disableButton ? 'CLOSE' : 'NO'}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleChangeRecommendation}
                        color="success"
                        autoFocus
                        disabled={disableButton}
                    >
                        YES
                    </Button>
                </DialogActions>
                <Typography
                        sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: "5px",
                            color: "#ff9800",

                        }}
                    >
                        {reccMessage}
                    </Typography>
            </Dialog>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    {{ alertMessage }}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default NewSurvey;
